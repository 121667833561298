import { useState } from "react";
import { useParams } from "react-router-dom";
import useCustomQuery from "@/hooks/useCustomQuery";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
  Input,
} from "@mui/material";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import { COLORS } from "@/constants/Colors/colors";
import Paginator from "@shared/Pagination/Paginator";
import CustomTableHead from "@shared/Layout/TableHead/CustomTableHead";
import CustomNoData from "@shared/Layout/NoDataTableRow/CustomNoData";
import { RemoveStudentSubscription } from "../../../Admin/Pages/Course/CourseStudents/RemoveStudentSubscription/RemoveStudentSubscription";
import { HashLoader } from "react-spinners";
import ColorButton from "@shared/Buttons/ColorButton/ColorButton";
import { Margin, Search } from "@mui/icons-material";

import { Button } from "@mui/material";
import { isTodayAfterDate } from "../../../utils/functions";
import { AddStudent } from "../../../Admin/Pages/Course/CourseStudents/components/AddStudent";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { FONTS } from "@/constants/Fonts/fonts";

export const CourseStudents = ({ courseData }) => {
  const lang = useSelector((state) => state?.global?.lang);
  const isDarkMode = useSelector((state) => state?.global?.isDarkMode);

  const [page, setPage] = useState(1);
  const [query, setQuery] = useState("0");
  const [keyword, setKeyword] = useState("");
  const { id } = useParams();
  const [open, setOpen] = useState(false);
  const [isSearch, setIsSearch] = useState(false);
  const { data, isPending, refetch } = useCustomQuery({
    url: `subscription/searchPhone?course_id=${id}&phoneQuery=${
      query || "0"
    }&page=${page}&isAddition=0`,
    method: "get",
    key: [query, "course", id, lang, page],
    lang,
  });

  console.log(data, "datadata");

  return (
    <>
      {courseData?.price > 0 && (
        <Box sx={{ width: "100%" }}>
          <Typography
            sx={{
              textAlign: "center",
              fontFamily: lang === "ar" ? "Noor-bold" : FONTS.ENGLISH,
              fontWeight: "bold",
              fontSize: 40,
              color: COLORS.DARK_GRAY,
              margin: "20px 0",
            }}
          >
            {lang == "ar"
              ? "الطلاب المسجلين بالكورس"
              : "Students registered for the course"}
          </Typography>
          <Box
            sx={{
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
            }}
          >
            <Box display={"flex"} alignItems={"center"} marginLeft={5}>
              <Input
                value={keyword}
                onChange={(e) => {
                  setKeyword(e?.target?.value);
                  if (e.target.value === "") {
                    setQuery("0");
                  }
                }}
                type="text"
                placeholder={
                  lang == "ar" ? "ابحث برقم الهاتف" : "Search by phone number"
                }
                sx={{
                  fontSize: 16,
                  width: 250,
                  height: "100%",
                  padding: "7px ",
                  fontFamily: lang === "ar" ? FONTS.REGULAR : FONTS.ENGLISH,
                }}
              />
              <ColorButton
                sx={{
                  width: 5,
                  height: 46,
                  borderRadius: 0,
                  boxShadow: "0 0 0 0",
                  bgcolor: COLORS.SECONDARY.MAIN,
                }}
                onClick={() => {
                  if (isSearch) setKeyword("");
                  setIsSearch(!isSearch);
                  setQuery(keyword);
                }}
              >
                {isSearch ? <CloseOutlinedIcon /> : <Search />}
              </ColorButton>
            </Box>
            <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
              {data?.data?.data?.length > 0 && (
                <Button
                  sx={{
                    bgcolor: COLORS.SECONDARY.MAIN,
                    color: COLORS.WHITE,
                    "&:hover": { bgcolor: COLORS.PRIMARY.MAIN },
                    margin: lang === "ar" ? "0 10px 0 0" : "0 0 0 10px",
                    fontFamily: lang === "en" && FONTS.ENGLISH,
                    textTransform: "capitalize",
                    fontSize: "16px",
                  }}
                  onClick={() => setOpen(true)}
                >
                  + {lang == "ar" ? "أضف طالب" : "Add Student"}
                </Button>
              )}
            </Box>
          </Box>
          <AddStudent
            open={open}
            setOpen={setOpen}
            closeParent={() => setOpen(false)}
            refetch={refetch}
          />
          {isPending ? (
            <Box
              sx={{ width: "100%", display: "flex", justifyContent: "center" }}
            >
              <HashLoader color={COLORS.PRIMARY.MAIN} />
            </Box>
          ) : (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                width: "100%",
                gap: 2,
              }}
            >
              <Table sx={{ width: "100%", marginTop: 2 }}>
                <CustomTableHead
                  tableHeaders={[
                    lang == "ar" ? "اسم الطالب" : "Student name",
                    lang == "ar" ? "رقم الطالب" : "Student number",
                    lang == "ar" ? "حالة الاشتراك" : "Subscription status",
                    lang == "ar" ? "طريقة الاشتراك" : "How to subscribe",
                    lang == "ar" ? "تاريخ التفعيل" : "Activation date",
                    lang == "ar" ? "المدة" : "Duration",
                    "",
                  ]}
                />
                <TableBody sx={{ width: "100%" }}>
                  {courseData?.students?.data?.length == 0 ? (
                    <>
                      <CustomNoData
                        numOfCells={6}
                        rowText={
                          query !== "0"
                            ? lang == "ar"
                              ? "لا يوجد طلاب بهذا الرقم"
                              : "There are no students with this number"
                            : lang == "ar"
                            ? "لا يوجد طلاب مسجلين بالكورس"
                            : "There are no students registered for the course"
                        }
                        button={
                          <Button
                            sx={{
                              mt: 5,
                              bgcolor: COLORS.SECONDARY.MAIN,
                              color: isDarkMode
                                ? COLORS.SOFT_DARK
                                : COLORS.WHITE,
                              "&:hover": { bgcolor: COLORS.PRIMARY.MAIN },
                              fontFamily:
                                lang === "ar" ? FONTS.BOLD : FONTS.ENGLISH,
                              fontWeight: "bold",
                              borderRadius: "10px",
                              padding: "8px 15px",
                              textTransform: "capitalize",
                              fontSize: "18px",
                            }}
                            onClick={() => setOpen(true)}
                          >
                            + {lang == "ar" ? "أضف طالب" : "Add student"}
                          </Button>
                        }
                      />
                    </>
                  ) : isSearch ? (
                    data?.data?.data?.map((student, index) => {
                      return (
                        <TableRow key={index}>
                          <TableCell
                            align="center"
                            sx={{
                              fontFamily:
                                lang === "ar" ? FONTS.REGULAR : FONTS.ENGLISH,
                            }}
                          >
                            {student?.user?.name}
                          </TableCell>
                          <TableCell
                            align="center"
                            sx={{
                              fontFamily:
                                lang === "ar" ? FONTS.REGULAR : FONTS.ENGLISH,
                            }}
                          >
                            {student?.user?.phone}
                          </TableCell>
                          <TableCell
                            align="center"
                            sx={{
                              fontFamily:
                                lang === "ar" ? FONTS.REGULAR : FONTS.ENGLISH,
                            }}
                          >
                            {student?.deleted_at
                              ? lang == "ar"
                                ? "محظور"
                                : "Forbidden"
                              : isTodayAfterDate(student?.expires_at)
                              ? lang == "ar"
                                ? "منتهي"
                                : "Finished"
                              : lang == "ar"
                              ? "مفعل"
                              : "Enabled"}
                          </TableCell>
                          <TableCell
                            align="center"
                            sx={{
                              fontFamily:
                                lang === "ar" ? FONTS.REGULAR : FONTS.ENGLISH,
                            }}
                          >
                            {student?.payment_code?.code
                              ? student?.payment_code?.code
                              : student?.paid
                              ? student?.paid
                              : lang == "ar"
                              ? "اشتراك عن طريق الادمن"
                              : "Subscribe via admin"}
                          </TableCell>
                          <TableCell
                            align="center"
                            sx={{
                              fontFamily:
                                lang === "ar" ? FONTS.REGULAR : FONTS.ENGLISH,
                            }}
                          >
                            {new Date(student?.created_at)
                              .toISOString()
                              .split("T")[0]
                              .split("-")
                              .reverse()
                              .join("-")}
                          </TableCell>
                          <TableCell
                            align="center"
                            sx={{
                              fontFamily:
                                lang === "ar" ? FONTS.REGULAR : FONTS.ENGLISH,
                            }}
                          >
                            {Math.round(
                              new Date(student?.expires_at).getTime() -
                                new Date(student?.created_at).getTime()
                            ) /
                              (1000 * 60 * 60 * 24)}
                            {lang == "ar" ? "يوم" : "day"}
                          </TableCell>

                          <TableCell
                            align="center"
                            sx={{
                              fontFamily:
                                lang === "ar" ? FONTS.REGULAR : FONTS.ENGLISH,
                            }}
                          >
                            <Box
                              sx={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                gap: 1,
                              }}
                            >
                              <RemoveStudentSubscription id={student.id} />
                            </Box>
                          </TableCell>
                        </TableRow>
                      );
                    })
                  ) : (
                    courseData?.students?.data?.map((student, index) => {
                      return (
                        <TableRow key={index}>
                          <TableCell align="center">
                            {student?.user?.name}
                          </TableCell>
                          <TableCell align="center">
                            {student?.user?.phone}
                          </TableCell>
                          <TableCell align="center">
                            {student?.deleted_at
                              ? lang == "ar"
                                ? "محظور"
                                : "Forbidden"
                              : isTodayAfterDate(student?.expires_at)
                              ? lang == "ar"
                                ? "منتهي"
                                : "Finished"
                              : lang == "ar"
                              ? "مفعل"
                              : "Enabled"}
                          </TableCell>
                          <TableCell align="center">
                            {student?.payment_code?.code
                              ? student?.payment_code?.code
                              : student?.paid
                              ? student?.paid
                              : lang == "ar"
                              ? "اشتراك عن طريق الادمن"
                              : "Subscribe via admin"}
                          </TableCell>
                          <TableCell align="center">
                            {new Date(student?.created_at)
                              .toISOString()
                              .split("T")[0]
                              .split("-")
                              .reverse()
                              .join("-")}
                          </TableCell>
                          <TableCell align="center">
                            {Math.round(
                              new Date(student?.expires_at).getTime() -
                                new Date(student?.created_at).getTime()
                            ) /
                              (1000 * 60 * 60 * 24)}{" "}
                            {lang == "ar" ? "يوم" : "day"}
                          </TableCell>

                          <TableCell align="center">
                            <Box
                              sx={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                gap: 1,
                              }}
                            >
                              <RemoveStudentSubscription id={student.id} />
                            </Box>
                          </TableCell>
                        </TableRow>
                      );
                    })
                  )}
                </TableBody>
              </Table>
              <Paginator
                sx={{ alignSelf: "center" }}
                page={page}
                setPage={setPage}
                totalPages={
                  isSearch
                    ? data?.data?.last_page
                    : courseData?.students?.last_page
                }
              />
            </Box>
          )}
        </Box>
      )}
    </>
  );
};

CourseStudents.propTypes = {
  courseData: PropTypes.object,
};
