import { useState } from "react";

export const initialSwapState = {
  first: null,
  second: null,
  active: false,
};

export const selectedStyles = {
  border: `5px solid #b0fe6a`,
  borderRadius: "5% 5% 10% 10% ",
};

export function useSwapState() {
  const [swapState, setSwapState] = useState(initialSwapState);
  return {
    swapState,
    setSwapState,
  };
}

export function selectedClickHandler(id, state, setState) {
  if (state.active) {
    if (!state.first) {
      setState((s) => {
        return { ...s, first: id };
      });
    } else if (id !== state.first) {
      setState((s) => {
        return { ...s, second: id };
      });
    }
  }
}

export function getSelectedStyles(id, state) {
  return state.first === id || state.second === id ? selectedStyles : {};
}
