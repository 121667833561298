import { Box, Button, Typography } from "@mui/material";
import React, { useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import { COLORS } from "@/constants/Colors/colors";
import Modal from "@shared/Modal/Modal";

import { AddVideo } from "./AddVideo/AddVideo";
import { AddFile } from "./AddFile/AddFile";
import { AddExam } from "./AddExam/AddExam";
import { AddHomework } from "./AddHomework/AddHomework";
import { useSelector } from 'react-redux';

import { QueryClient, QueryClientProvider } from 'react-query';


export const Add = ({ module, homework, exams }) => {
  const language = useSelector((state) => state?.global);
  const lang = language?.lang;
  const [open, setOpen] = useState(false);

  const queryClient = new QueryClient();

  return (
    <>
      <Button
        onClick={() => setOpen(true)}
        sx={{
          bgcolor: COLORS.PRIMARY.MAIN,
          color: COLORS.WHITE,
          width: "fit-content",
          margin: "0 auto",
          "&:hover": { bgcolor: COLORS.WHITE, color: COLORS.PRIMARY.MAIN },
        }}
      >
        <AddIcon fontSize="small" sx={{}} />
        <Typography>{lang == "ar" ? "أضف" : "Add"}</Typography>
      </Button>
      <Modal
        zIndex={"1300"}
        open={open}
        closeFn={() => setOpen(false)}
        maxWidth={"sm"}
      >
        <Box
          sx={{ display: "grid", gridTemplateColumns: "repeat(2,1fr)", gap: 1 }}
        >
          <QueryClientProvider client={queryClient}>
            <AddVideo module={module} close={() => setOpen(false)} />
          </QueryClientProvider>
          <AddFile module={module} />
          {!exams && (
            <AddExam id={module?.id} closeParent={() => setOpen(false)} />
          )}
          {!homework && (
            <AddHomework module={module} closeParent={() => setOpen(false)} />
          )}
        </Box>
      </Modal>
    </>
  );
};
