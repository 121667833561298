import { COLORS } from "@/constants/Colors/colors";
import { USER_TOKEN } from "@/constants/tokens";
import { Box, Button, Typography } from "@mui/material";
import useCustomMutation from "@/hooks/useCustomMutation";
import Modal from "@shared/Modal/Modal";
import { notifySuccess } from "@shared/Toast/toast";
import React from "react";
import { useNavigate } from "react-router-dom";
import { HashLoader } from "react-spinners";
import { useSelector } from "react-redux";
import { FONTS } from "@/constants/Fonts/fonts";

export const Logout = ({ open, setOpen }) => {
  const navigate = useNavigate();
  const lang = useSelector((state) => state?.global?.lang);
  const { mutate, isPending } = useCustomMutation({
    url: `logout`,
    onSuccess: () => {
      notifySuccess(
        lang == "ar" ? "تم تسجيل الخروج بنجاح" : "Logout succefully"
      );
      localStorage.removeItem(USER_TOKEN);
      navigate("/");
    },
  });
  return (
    <Modal maxWidth={"sm"} open={open} closeFn={() => setOpen(false)}>
      <Typography sx={{ fontSize: 30, fontFamily: lang === 'ar' ? FONTS.BOLD : FONTS.ENGLISH, color: COLORS.WHITE }}>
        {lang == "ar" ? "هل تريد تسجبل الخروج" : "Do you want to logout?"}
      </Typography>
      <Box sx={{ display: "flex", marginY: 2, gap: 2 }}>
        <Button
          onClick={() => mutate()}
          sx={{
            bgcolor: COLORS.DANGER,
            color: COLORS.WHITE,
            "&:hover": { bgcolor: COLORS.DANGER },
            fontFamily: lang === 'ar' ? "" : FONTS.ENGLISH
          }}
        >
          {isPending ? (
            <HashLoader size={20} color={COLORS.WHITE} />
          ) : lang == "ar" ? (
            "نعم"
          ) : (
            "Yes"
          )}
        </Button>
        <Button
          onClick={() => setOpen(false)}
          sx={{
            bgcolor: COLORS.PRIMARY.MAIN,
            color: COLORS.WHITE,
            "&:hover": { bgcolor: COLORS.PRIMARY.MAIN },
            fontFamily: lang === 'ar' ? "" : FONTS.ENGLISH
          }}
        >
          {lang == "ar" ? "الغاء" : "Cancel"}
        </Button>
      </Box>
    </Modal>
  );
};
