import { COLORS } from "@/constants/Colors/colors";
import { Box, Typography, List, ListItem } from "@mui/material";
import { useSelector } from "react-redux";

export default function PrivacyPolicy() {
  const globalState = useSelector((state) => state?.global);
  return (
    <>
      <Box
        sx={{
          color: globalState?.isDarkMode ? COLORS.WHITE : "#2a2a2a",
          textAlign: "start",
          fontFamily: "Noor-Bold",
          py: 6,
          px: 6,
          bgcolor: globalState?.isDarkMode ? COLORS.DARK_GRAY : "white",
        }}
      >
        <Typography fontFamily={"Noor-regular"} fontSize={48} mb={2}>
          سياسة الخصوصية
        </Typography>
        <Typography fontSize={18}>
          نحن في Alpha ندرك أنك تهتم بكيفية استخدام ومشاركة معلوماتك
          الشخصية، ونحن نأخذ خصوصيتك على محمل الجد. يرجى قراءة ما يلي لمعرفة
          المزيد حول ممارساتنا في الخصوصية. بالاستخدام أو الوصول إلى موقعنا
          وخدماتنا، فإنك تقر بأنك قد قرأت وفهمت وتوافق على التزام جميع أحكام هذه
          سياسة الخصوصية وشروط الخدمة الخاصة بنا.
        </Typography>

        <Typography fontFamily={"Noor-Bold"} fontSize={28} mt={4} mb={2}>
          المعلومات التي نجمعها
        </Typography>
        <Typography fontSize={18}>
          نجمع المعلومات منك عندما تسجل على موقعنا، أو تشترك في النشرة الإخبارية
          لدينا، أو ترد على استبيان، أو تملأ نموذجًا، أو تستخدم ميزات الموقع
          الأخرى. عند التسجيل أو الاشتراك على موقعنا، يمكن أن يُطلب منك إدخال
          اسمك وعنوان بريدك الإلكتروني وعنوانك البريدي ورقم هاتفك ومعلومات بطاقة
          الائتمان أو تفاصيل أخرى لمساعدتك في تجربتك. كما قد نجمع معلومات غير
          شخصية عن استخدامك لموقعنا، مثل نوع المتصفح الذي تستخدمه ونوع نظام
          التشغيل الذي تستخدمه واسم النطاق لمزود خدمة الإنترنت الخاص بك.
        </Typography>

        <Typography fontFamily={"Noor-Bold"} fontSize={28} mt={4} mb={2}>
          كيفية استخدامنا لمعلوماتك
        </Typography>
        <Typography fontSize={18}>
          يمكننا استخدام المعلومات التي نجمعها منك للقيام بما يلي:
        </Typography>
        <List
          sx={{
            listStyleType: "disc",
          }}
        >
          <ListItem
            sx={{
              textAlign: "start",
              display: "list-item",
              fontSize: 16,
              fontFamily: "Noor-regular",
            }}
          >
            تخصيص تجربتك والسماح لنا بتقديم نوع المحتوى وعروض المنتجات التي تهمك
            أكثر.
          </ListItem>
          <ListItem
            sx={{
              textAlign: "start",
              display: "list-item",
              fontSize: 16,
              fontFamily: "Noor-regular",
            }}
          >
            تحسين موقعنا لخدمتك بشكل أفضل.
          </ListItem>
          <ListItem
            sx={{
              textAlign: "start",
              display: "list-item",
              fontSize: 16,
              fontFamily: "Noor-regular",
            }}
          >
            السماح لنا بتقديم خدمة أفضل في الاستجابة لطلبات خدمة العملاء الخاصة
            بك.
          </ListItem>
          <ListItem
            sx={{
              textAlign: "start",
              display: "list-item",
              fontSize: 16,
              fontFamily: "Noor-regular",
            }}
          >
            إدارة مسابقة أو ترقية أو استبيان أو ميزة أخرى للموقع. معالجة
            المعاملات.
          </ListItem>
          <ListItem
            sx={{
              textAlign: "start",
              display: "list-item",
              fontSize: 16,
              fontFamily: "Noor-regular",
            }}
          >
            إرسال رسائل البريد الإلكتروني الدورية بشأن طلبك أو منتجات وخدمات
            أخرى.
          </ListItem>
        </List>

        <Typography fontFamily={"Noor-Bold"} fontSize={28} mt={4} mb={2}>
          كيفية حماية معلوماتك
        </Typography>
        <Typography fontSize={18}>
          نقوم بتطبيق مجموعة متنوعة من تدابير الأمان للحفاظ على سلامة معلوماتك
          الشخصية عندإجراء طلب أو إدخال أو إرسال أو الوصول إلى معلوماتك الشخصية.
          نحن نقدم استخدام خادم آمن. يتم إرسال جميع المعلومات الحساسة /
          الائتمانية المقدمة عبر تقنية SSL (Secure Socket Layer) وتشفيرها في
          قاعدة بيانات مزودي بوابة الدفع الخاصة بنا فقط ليتم الوصول إليها من قبل
          أولئك الذين تم تفويضهم بحقوق الوصول الخاصة بهذه الأنظمة، ويتعين عليهم
          الحفاظ على سرية المعلومات.
        </Typography>
        <Typography fontFamily={"Noor-Bold"} fontSize={28} mt={4} mb={2}>
          الكشف عن المعلومات لأطراف ثالثة
        </Typography>
        <Typography fontSize={18}>
          نحن لا نبيع أو نتاجر أو ننقل معلوماتك الشخصية المحددة إلى أطراف
          خارجية. ولا يشمل هذا الأمر الأطراف الثالثة الموثوق بها التي تساعدنا في
          تشغيل موقعنا أو إجراء أعمالنا أو خدمتك، طالما أن تلك الأطراف توافق على
          الحفاظ على هذه المعلومات سرية. قد نقوم أيضًا بالكشف عن معلوماتك عندما
          نعتقد أن الكشف مناسب للامتثال للقانون أو تنفيذ سياسات موقعنا أو حماية
          حقوقنا أو حقوق الآخرين أو الممتلكات أو السلامة.
        </Typography>

        <Typography fontFamily={"Noor-Bold"} fontSize={28} mt={4} mb={2}>
          روابط الأطراف الثالثة
        </Typography>
        <Typography fontSize={18}>
          أحيانًا، بموجب تقديرنا، قد نشمل أو نقدم منتجات أو خدمات من الأطراف
          الثالثة على موقعنا. تحتوي هذه المواقع الثالثة على سياسات خصوصية منفصلة
          ومستقلة. لذلك، ليس لدينا أي مسؤولية أو مسؤولية عن محتوى وأنشطة هذه
          المواقع المرتبطة. نحن نسعى لحماية سلامة موقعنا ونرحب بأي ملاحظات عن
          هذه المواقع.
        </Typography>
        <Typography fontFamily={"Noor-Bold"} fontSize={28} mt={4} mb={2}>
          تغييرات على سياسة الخصوصية لدينا
        </Typography>
        <Typography fontSize={18}>
          قد نقوم بتحديث سياسة الخصوصية هذه من وقت لآخر. إذا قمنا بإجراء تغييرات
          جوهرية في كيفية معاملتنا لمعلوماتك الشخصية، فسنخطرك بذلك عن طريق
          البريد الإلكتروني إلى عنوان البريد الإلكتروني المحدد في حسابك و / أو
          من خلال إشعار على صفحة البداية الخاصة بموقعنا. يشكل استمرار استخدام
          الموقع بعد مثل هذا الإشعار موافقتك على التغييرات.
        </Typography>
      </Box>
    </>
  );
}
