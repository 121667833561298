import React, { useState } from 'react'
import layoutStyle from "./loginSignupLayout.module.css"
import Signin from '../pages/signin/signin';
import Signup from '../pages/signup/signup';
import { FaAngleLeft } from "react-icons/fa6";
import whiteLogo from "../../../assets/images/whiteLogo.png"
import darkLogo from "../../../assets/images/darkLogo.png"

import { useSelector } from 'react-redux';
import whitePatternBakground from "../../../assets/images/backgroundStudy.png"
import darkPatternBakground from "../../../assets/images/darkBackgroundStudy.png"
import { COLORS } from '@/constants/Colors/colors';
import { FONTS } from '@/constants/Fonts/fonts';


const LoginSignup = () => {
    const globalState = useSelector((state) => state?.global);
    const lang = globalState?.lang;
    const isDarkMode = !globalState?.isDarkMode;

    const [signIn, toggle] = useState(true);

    return (
        <div className={layoutStyle.container} style={{ backgroundColor: isDarkMode ? COLORS.WHITE : COLORS.SOFT_DARK }}>
            <Signup signIn={signIn} onClick={() => toggle(true)} />
            <Signin signIn={signIn} onClick={() => toggle(false)} />

            <div className={`${layoutStyle.overlayContainer} ${!signIn ? layoutStyle.overlayContainerSlide : ""}`}>

                <div className={`${layoutStyle.overlay} ${!signIn ? layoutStyle.overlaySlide : ""}`} style={lang === 'ar' ? { direction: 'rtl' } : { direction: 'ltr', fontFamily: FONTS.ENGLISH }}>
                    <span style={{ backgroundImage: `url(${isDarkMode ? whitePatternBakground : darkPatternBakground})` }}></span>
                    <div className={`${layoutStyle.overlayPanel} ${layoutStyle.leftOvelay} ${!signIn ? layoutStyle.leftOvelaySlide : ""}`}>
                        <img src={isDarkMode ? whiteLogo : darkLogo} alt="" />
                        <h1 className={layoutStyle.title} style={{ color: isDarkMode ? COLORS.WHITE : COLORS.SOFT_DARK }}>{lang === 'ar' ? "انضم إلينا!" : "Joining Us!"}</h1>
                        <p style={{ color: isDarkMode ? COLORS.WHITE : COLORS.SOFT_DARK, fontWeight: lang === 'ar' ? "bold" : "normal" }}>{lang === 'ar' ? "انضم إلى مجتمع المتعلمين لدينا وابدأ رحلتك التعليمية اليوم!" : "Join our community of learners and start your educational journey today!"}</p>
                        <button className={layoutStyle.slideButton} onClick={() => toggle(true)} style={isDarkMode ? { color: COLORS.WHITE, borderColor: 'rgba(255, 255, 255, 0.45)' } : { color: COLORS.SOFT_DARK, fontWeight: lang === 'ar' ? "bold" : "normal", borderColor: 'rgba(60, 60, 60, 0.45)' }}>{lang === 'ar' ? "تسجيل الدخول" : "Sign In"}</button>
                    </div>
                    <div className={`${layoutStyle.overlayPanel} ${layoutStyle.rightOverlay} ${!signIn ? layoutStyle.rightOverlaySlide : ""}`}>
                        <img src={isDarkMode ? whiteLogo : darkLogo} alt="" />
                        <h1 className={`${layoutStyle.title}`} style={{ color: isDarkMode ? COLORS.WHITE : COLORS.SOFT_DARK }}>{lang === 'ar' ? "مرحبًا بعودتك!" : "Welcome Back!"}</h1>
                        <p style={{ color: isDarkMode ? COLORS.WHITE : COLORS.SOFT_DARK, fontWeight: lang === 'ar' ? "bold" : "normal" }}>{lang === 'ar' ? "هل أنت مستعد لمواصلة رحلتك التعليمية؟ درسك التالي بانتظارك بعد تسجيل الدخول مباشرة." : "Ready to continue your learning journey? Your next lesson is just a login away."}</p>
                        <button className={layoutStyle.slideButton} onClick={() => toggle(false)} style={isDarkMode ? { color: COLORS.WHITE, borderColor: 'rgba(255, 255, 255, 0.45)' } : { color: COLORS.SOFT_DARK, fontWeight: lang === 'ar' ? "bold" : "normal", borderColor: 'rgba(60, 60, 60, 0.45)' }}>{lang === 'ar' ? "اشترك الآن" : "Sign Up"}</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default LoginSignup


