import { Box, Button } from "@mui/material";
import { Link } from "react-router-dom";
import logo from "@assets/images/logo.png";
import { useTheme } from "@mui/material/styles";
import { COLORS } from "@/constants/Colors/colors";
import ModeTuggle from "@/modules/settings/components/ModeTuggle";
import { useSelector } from "react-redux";
import LangTuggle from "@/modules/settings/components/LangTuggle/LangTuggle";
import { FONTS } from './../../../../constants/Fonts/fonts';
export default function NavBar({ bar }) {
  const theme = useTheme();
  const globalState = useSelector((state) => state?.global);
  const lang = globalState?.lang;

  return (
    <>
      <Box
        id="nav"
        backgroundColor={globalState?.isDarkMode ? COLORS.SOFT_DARK : "white"}
        display={"flex"}
        justifyContent={"space-between"}
        alignItems={"center"}
        px={3}
        sx={{ direction: lang == "ar" ? "rtl" : "ltr" }}
      >
        <Link
          to={"/"}
          style={{
            textDecoration: "none",
          }}
        >
          <Box sx={{
            width: "80px",
            [theme.breakpoints.down("lg")]: {
                width: '50px',
                margin: lang==='ar' ? "0 -15px 0 -18px" : "0 -15px"
            }
          }}>
            <img
              src={logo}
              alt="logo"
              className="logo"
              style={{
                width: "100%",
              }}
            />
          </Box>

        </Link>
        <Box
          display={"flex"}
          justifyContent={"center"}
          gap={1}
          alignItems={"center"}
        >
          <Box sx={{
            display: "flex", alignItems: "center",
            [theme.breakpoints.down("lg")]: {
              transform: 'scale(0.75)',

            }
          }}>
            {/* <ModeTuggle /> */}
            <LangTuggle />
          </Box>
          <Link
            to={"/login"}
            style={{ textDecoration: "none", color: "white" }}
          >
            <Button
              sx={{
                color: "white",
                backgroundColor: COLORS.SECONDARY.MAIN,
                borderRadius: "8px",
                px: 2,
                py: 1,
                fontSize: 14,
                [theme.breakpoints.down("lg")]: {
                  fontSize: lang === 'ar' ? 12 : 14,
                  px: lang === 'ar' ? 1 :  2,
                  width: '100px',
                  margin: lang === 'ar' ? "0 -20px" : "0 0 0 -20px"
                },
                fontFamily: lang === 'ar' ? "Noor-bold" : FONTS.ENGLISH,
                "&:hover": {
                  backgroundColor: COLORS.PRIMARY.MAIN,
                },
              }}
            >
              {lang == "ar" ? "تسجيل الدخول" : "Log in"}
            </Button>
          </Link>
        </Box>
      </Box>
    </>
  );
}
