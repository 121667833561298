import React from "react";
import {
  Typography,
  Container,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";

export default function TermsOfUsePage() {
  return (
    <Container maxWidth="md">
      <Typography variant="h4" gutterBottom>
        شروط الاستخدام
      </Typography>
      <Typography paragraph>
        تشير شروط استخدام Alpha إلى مجموعة من الشروط والأحكام التي تحدد
        الاتفاقية القانونية بين المستخدم وموقع الويب أو النظام الأساسي. تم تصميم
        هذه الشروط لحماية حقوق المستخدم وحقوق موقعنا أو منصتنا.
      </Typography>
      <Typography paragraph>
        يعد فهم شروط استخدام Alpha أمرًا بالغ الأهميةأنت لضمان تجربة آمنة
        وعادلة عبر الإنترنت
      </Typography>
      <Typography paragraph>
        معلومات أساسية عن شروط استخدام Alpha تغطي شروط استخدام Alpha
        جوانب مختلفة مثل الخصوصية وحقوق الملكية الفكرية واستخدام المحتوى وسلوك
        المستخدم وقيود المسؤولية وحل النزاعات. نحن ننفذ هذه الشروط لتوضيح حقوقنا
        ومسؤولياتنا، وكذلك لتخفيف المشكلات القانونية المحتملة.
      </Typography>
      <Typography variant="h5" gutterBottom>
        الاعتبارات الرئيسية لأنت عند استخدام موقعنا أو منصتنا، من المهمأنت
        لقراءة وفهم شروط الاستخدام الخاصة بـ Alpha. وفيما يلي بعض
        الاعتبارات الرئيسية لأنت :
      </Typography>
      <List>
        <ListItem sx={{ textAlign: "right" }}>
          <ListItemText
            primary="الخصوصية :"
            secondary="تحقق من كيفية جمع معلوماتك الشخصية وتخزينها واستخدامها بواسطة موقع الويب أو النظام الأساسي.
            الملكية الفكرية : فهم الحقوق والقيود المرتبطة بالمحتوى الذي تقوم بإنشائه أو مشاركته على المنصة.
            سلوك المستخدم:تعرف على القواعد والإرشادات الخاصة بالسلوك المقبول على موقعنا أو منصتنا."
          />
        </ListItem>
        <ListItem sx={{ textAlign: "right" }}>
          <ListItemText
            primary="مسئولية قانونية :"
            secondary="كن على دراية بحدود المسؤولية تجاه موقع الويب أو النظام الأساسي والمخاطر المحتملة المرتبطة باستخدامهملكنا خدمات.
            حل النزاعات : مراجعة إجراءات حل أي نزاعات قد تنشأ بينك وبين الموقع أو المنصة.
            من خلال المراجعة الدقيقة والالتزام بشروط استخدام Alpha،أنت يمكن أن يضمن تجربة إيجابية وآمنة عبر الإنترنت."
          />
        </ListItem>
        <ListItem sx={{ textAlign: "right" }}>
          <ListItemText
            primary="قبول الشروط"
            secondary="عند الوصول إلى موقع Alpha أو استخدامه، من المهم فهم شروط الاستخدام الموضحة هنا وقبولها.
            من خلال الوصول إلى الموقع أو استخدامه، فإنك توافق على الالتزام بهذه الشروط وأي قوانين ولوائح معمول بها."
          />
        </ListItem>
        <ListItem sx={{ textAlign: "right" }}>
          <ListItemText
            primary="ما هي شروط الاستخدام الخاصة بـ Alpha ؟"
            secondary="تحكم شروط استخدام Alpha استخدامك للموقع وأي خدمات مقدمة.
            تحدد هذه الشروط حقوقك ومسؤولياتك كمستخدم وتوفر إرشادات للسلوك المناسب أثناء استخدام النظام الأساسي.
            "
          />
        </ListItem>
        <ListItem sx={{ textAlign: "right" }}>
          <ListItemText
            primary="ماذا عليك ان تفعل ؟"
            secondary="للتأكد من امتثالك لشروط الاستخدام الخاصة بنا، اقرأها بعناية وافهم الالتزامات التي تفرضها عليك. إذا كنت لا توافق على أي من الشروط، فامتنع عن الوصول إلى موقعنا أو استخدامه.
            "
          />
        </ListItem>
        <ListItem sx={{ textAlign: "right" }}>
          <ListItemText
            primary="لماذا تعتبر المصطلحات مهمة ؟"
            secondary="شروط الاستخدام الخاصة بـ Alpha تحمي كليهماأنت والشركة من خلال تحديد توقعات السلوك على الموقع.
            وهي تحدد الاستخدامات المقبولة للمنصة، وحقوق الملكية الفكرية، وحدود المسؤولية.
            من خلال قبول شروط الاستخدام هذه، فإنك تقر بأهمية احترام حقوق الآخرين، والحفاظ على خصوصية وأمان حسابك، واستخدام الخدمات بطريقة مسؤولة.
            "
          />
        </ListItem>
        <ListItem sx={{ textAlign: "right" }}>
          <ListItemText
            primary="التزامات المستخدم"
            secondary="– مسؤوليات المستخدم والتزاماته بموجب شروط الاستخدام.عند استخدام منصة Alpha،أنت مطلوب منهم الالتزام بشروط الاستخدام.

            – تحدد هذه الشروط المسؤوليات والالتزامات المتوقعة منهاأنت لضمان بيئة آمنة ومحترمة للجميع.
           
            – أنت ويجب عليهم التعرف على هذه الشروط لتجنب أي انتهاكات أو سوء استخدام للمنصة.
           
           الإجراءات والمحتوى المحظور
           للحفاظ على سلامة منصة Alpha، يُحظر تمامًا القيام ببعض الإجراءات والمحتوى.
           
           أنت يجب ألا تشارك في أي أنشطة غير قانونية، أو تضايق الآخرين أو تؤذيهم، أو تشارك محتوى غير لائق أو مسيء.
           
           بالإضافة إلى ذلك،أنت يجب الامتناع عن انتهاك أي حقوق ملكية فكرية أو التدخل في وظائف المنصة.
           
           من المهم لأنت لفهمملكنا الالتزامات واتباع الإرشادات المنصوص عليها في شروط الاستخدام لإنشاء مجتمع إيجابي وجدير بالثقة عبر الإنترنت.
           
           ومن خلال القيام بذلك، يمكن للجميع الاستمتاع بتجربة آمنة وممتعة.
            "
          />
        </ListItem>
        <ListItem sx={{ textAlign: "right" }}>
          <ListItemText
            primary="حقوق الملكية الفكرية :"
            secondary="عند استخدام شروط استخدام Alpha، من المهم فهم ملكية المحتوى والملكية الفكرية.

            يظل كل المحتوى الذي أنشأه المستخدم، مثل النصوص والصور ومقاطع الفيديو، ملكًا للمستخدم أوملكنا أصحابها. لا تطالب Alpha بملكية أي محتوى ينشئه المستخدم.
            
            لكن بالموافقة على الشروطأنت منح Alpha ترخيصًا غير حصري وعالميًا وخاليًا من حقوق الملكية لاستخدام وإعادة إنتاج وتوزيعملكنا المحتوى لأغراض ترويجية.
            "
          />
        </ListItem>
        <ListItem sx={{ textAlign: "right" }}>
          <ListItemText
            primary="حقوق المستخدم والأذونات :"
            secondary="كما هو مذكور في البيانات عبر الإنترنت، يتم منح زوار هذا الموقع إذنًا للوصول إلى المواد وتنزيلها للاستخدام غير التجاري فقط.

            ومع ذلك، من المهم ملاحظة أن المستخدمين محظور عليهم نسخ أو توزيع أو إعادة إنتاج أو بيع أو نشر أي مواد محمية بحقوق الطبع والنشر تم الحصول عليها من هذا الموقع.
            
            بالإضافة إلى ذلك، لا يتم منح أي إذن لنقل أي مواد تم تنزيلها إلى موقع إنترنت مفتوح دون موافقة مسبقة. ويجب على المستخدمين الاتصال بنا للحصول على إذن في مثل هذه الحالات.
            
            فيما يتعلق بحقوق المستخدم، إذا كنت مقيمًا في أوروبا، فلديك الحق في الوصول إلى المعلومات الشخصية التي نحتفظ بها أو تصحيحها أو تحديثها أو حذفها. لممارسة هذا الحق، يمكنك الاتصال بنا باستخدام معلومات الاتصال المقدمة.
            
            من المهم أن نفهم أنه من خلال التنقل والتفاعل مع موقعنا، يوافق المستخدمون على الالتزام بشروط الاستخدام المنصوص عليها في البيانات عبر الإنترنت.
            
            قد يتم تحديث هذه الشروط بشكل دوري دون إشعار مسبق، لذا ننصحك بمراجعتها بانتظام.
            
            جمع البيانات واستخدامها
            ملكناتنص سياسة الخصوصية بوضوح على البيانات التي سيتم جمعها منهاأنت وكيف سيتم استخدامه.
            
            قد يتضمن ذلك معلومات شخصية مثل الاسم وعنوان البريد الإلكتروني وتفاصيل الدفع.
            
            من المهم بالنسبة لنا أن نتحلى بالشفافيةملكنا ممارسات جمع البيانات والحصول على موافقة المستخدم.
            
            بشكل عام، توضح سياسة الخصوصية الشاملة الخاصة بنا ضمن شروط الاستخدام التزام شركتنا بحماية خصوصيتك ويمكنها بناء الثقة مع العملاء.
            
            ومن الأهمية بمكان أن تقوم شركاتنا بمراجعة وتحديث هذه المعلومات بانتظامملكنا سياسات الخصوصية لضمان الامتثال للقوانين واللوائح المعمول بها.
            
            إنهاء الاتفاق
            "
          />
        </ListItem>
        <ListItem sx={{ textAlign: "right" }}>
          <ListItemText
            primary="شروط إنهاء شروط الاستخدام :"
            secondary="من أجل ضمان علاقة سلسة وعادلة لديناوضعت الشركة شروطًا معينة لإنهاء شروط الاستخدام الخاصة بها.
            ويشمل ذلك انتهاكات الشروط، أو إساءة استخدام المنصة، أو أي خرق آخر لسياسات الشركة.
            من المهم لأنت للتعرف على هذه الشروط لتجنب أي سوء فهم أو عواقب غير مقصودة.
            عواقب الإنهاء
            عند إنهاء شروط الاستخدام، فمن الضروريأنت ليكون على بينة من العواقب المحتملة.
            
            وقد يشمل ذلك تعليق حسابك أو إنهائه، وفقدان الوصول إلى ميزات أو خدمات معينة، واتخاذ إجراء قانوني محتمل عن أي أضرار ناجمة.
            
            أنت يجب أن تأخذ ذلك في الاعتبار وتلتزم بالإرشادات المنصوص عليها في شروط الاستخدام للحفاظ على علاقة إيجابية وطويلة الأمد مع الشركة.
            
            حل النزاعات
            عندما يتعلق الأمر بحل النزاعات أو الصراعات، من المهم بالنسبة لنا أن يكون لدينا أساليب وإجراءات واضحة وفعالة. وهذا يضمن إمكانية معالجة المشكلات وحلها بطريقة عادلة وفعالة.
            
            "
          />
        </ListItem>
        <ListItem sx={{ textAlign: "right" }}>
          <ListItemText
            primary="تشمل بعض الطرق الشائعة لحل النزاعات التفاوض والوساطة والتحكيم:
            "
            secondary="تفاوض : يتضمن مناقشة الطرفين للمسألة ومحاولة التوصل إلى اتفاق متبادل المنفعة.
            وساطة : تتضمن طرفًا ثالثًا محايدًا يساعد في تسهيل التوصل إلى حل.
            تحكم : من ناحية أخرى، ينطوي على قيام طرف ثالث باتخاذ قرار ملزم بعد الاستماع إلى طرفي الحجة.
            حدود المسؤولية
            من المهم لأنت لفهم وقبول حدود المسؤولية عند استخدام أي خدمة أو منصة.
            تحتوي شروط الاستخدام والأحكام الخاصة بنا على إخلاء المسؤولية التي تحمي شركتنا أو منصتنا من التزامات معينة.
            تتضمن إخلاءات المسؤولية هذه بيانات تحد من مسؤوليتنا عن أي أضرار أو خسائر يتكبدها المستخدم.
            أنت ينبغي مراجعة شروط الاستخدام بعناية وفهم نطاق القيود المفروضة على المسؤولية.
            بينما نسعى جاهدين لتقديم خدمات دقيقة وموثوقة، قد تكون هناك حالات تحدث فيها أخطاء أو خلل فني.
            إنه أمر بالغ الأهمية لأنت لتكون على دراية بهذه المخاطر المحتملة وتقبلها عند الموافقة على شروط الاستخدام.
            فهم المستخدم وقبوله للمخاطر
            عند استخدام خدمتنا أو منصتنا،أنت يجب أن يفهم ويقبل المخاطر التي تنطوي عليها.
            
            على سبيل المثال، قد ينطوي ذلك على مخاطر مثل اختراق البيانات أو الوصول غير المصرح به إلى المعلومات الشخصية.
            
            أنت يجب أن تكون على دراية بهذه المخاطر وأن تتخذ التدابير المناسبة لحمايتكالبيانات والخصوصية.
            "
          />
        </ListItem>
        <ListItem sx={{ textAlign: "right" }}>
          <ListItemText
            primary="شروط تعديل شروط الاستخدام :
            "
            secondary="نحن نحتفظ بالحق في إجراء تغييرات على شروط الاستخدام الخاصة بنا في أي وقت.

            قد تكون هذه التعديلات بسبب المتطلبات القانونية أو تحسينات النظام الأساسي أو لأسباب أخرى تعتبر ضرورية.
            
            من الضروري مراجعة شروط الاستخدام بانتظام للبقاء على اطلاع بأية تغييرات قد تؤثر على حقوقك والتزاماتك كمستخدم.
            
            الإخطار وتاريخ سريان التغييرات
            عندما يتم إجراء تغييرات كبيرة على شروط الاستخدام، سنقوم بإخطارك بذلكأنت من خلال القنوات المناسبة.
            
            وقد يشمل ذلك إرسال إشعارات عبر البريد الإلكتروني، أو عرض الإشعارات على المنصة، أو طرق أخرى حسب ما نراه مناسبًا.
            
            ستصبح التغييرات سارية اعتبارًا من التاريخ المحدد المذكور في الإخطار.
            
            يعد فهم شروط الاستخدام والالتزام بها أمرًا حيويًا للاستخدام السلس والمتوافق لمنصتنا وخدماتنا. تأكد من البقاء على اطلاع بأي تعديلات لضمان تجربة سلسة.
            "
          />
        </ListItem>
      </List>
    </Container>
  );
}
