import { useMutation } from "react-query";
import { notifyError, notifySuccess } from "@shared/Toast/toast";
import useAxiosConfig from "@shared/Helpers/axios/useAxiosConfig";
import { useQueryClient } from "@tanstack/react-query";

const useAddVideo = ({ lang, onSuccess }) => {
  const queryClient = useQueryClient();
  const instance = useAxiosConfig(lang);

  const addModuleVideo = async (videoData) => {
    const formData = new FormData();
    formData.append("name", videoData.name);
    formData.append("link", videoData.link);
    formData.append("module_id", videoData.module_id);

    if (!videoData.link) {
      formData.delete("link");
    }

    try {
      const response_store = await instance.post(`lesson/store`, formData, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      if (response_store.data?.video_id !== null) {
        const data = response_store.data;
        const formUploadVideo = new FormData();

        // Ensure the video data is valid before appending it
        if (videoData?.video && videoData?.video instanceof File) {
          formUploadVideo.append("video", videoData.video);
        } else {
          throw new Error("Invalid video file");
        }

        const response = await instance.post(
          `lesson/upload/${data.id}`,
          formUploadVideo,
          {
            headers: {
              "Content-Type": "multipart/form-data", // Change to multipart/form-data
            },
          }
        );

        queryClient.invalidateQueries(["course modules"]);
        console.log("Video uploaded successfully:", response.data);
        return response.data;
      }
      queryClient.invalidateQueries(["course modules"]);
    } catch (error) {
      console.error("Error occurred:", error);
      throw error; // Re-throw the error so it can be caught in useMutation
    }
  };

  const mutation = useMutation(addModuleVideo, {
    onSuccess: (data) => {
      notifySuccess(
        lang === "ar" ? "تم رفع الفيديو بنجاح" : "Video uploaded successfully"
      );
      onSuccess(data);
    },
    onError: (error) => {
      console.error("Error uploading video:", error);
      notifyError(error?.response?.data?.message || "An error occurred");
    },
  });

  return {
    addModuleVideo: mutation.mutate,
    isPending: mutation.isLoading,
    error: mutation.error,
  };
};

export default useAddVideo;
