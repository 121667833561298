import {
  Box,
  Button,
  FormHelperText,
  TextField,
  Typography,
} from "@mui/material";
import VideocamIcon from "@mui/icons-material/Videocam";
import { COLORS } from "@/constants/Colors/colors";
import { useState } from "react";
import Modal from "@shared/Modal/Modal";
import { FormProvider, useForm } from "react-hook-form";
import ColorButton from "@shared/Buttons/ColorButton/ColorButton";
import { HashLoader } from "react-spinners";
import { newVideoSchema } from "../../../schema/newVedio.schema";
import { zodResolver } from "@hookform/resolvers/zod";
import useAddVideo from "../../hook/useAddVideo";
import { useSelector } from "react-redux";
import Form from "@shared/Form/Form";
import style from "./AddVideo.module.css";
import { notifyError } from "@shared/Toast/toast";

export const AddVideo = ({ module, close }) => {
  const globalState = useSelector((state) => state?.global);
  const lang = globalState?.lang;
  const isDarkMode = globalState?.isDarkMode;
  const [open, setOpen] = useState(false);

  const methods = useForm({
    resolver: zodResolver(newVideoSchema),
  });

  const {
    register,
    setValue,
    formState: { errors },
    handleSubmit,
  } = useForm();

  const { addModuleVideo, isPending } = useAddVideo({
    lang,
    onSuccess: () => {
      setOpen(false);
      close();
    },
  });

  function submit(data) {
    if (!data.name && data.video?.name) {
      const videoNameWithoutExtension = data.video.name
        .split(".")
        .slice(0, -1)
        .join(".");
      data.name = videoNameWithoutExtension;
    }
    console.log("test", data);
    console.log("video", data.video);
    // if (!data.video) {
    //   notifyError("يجب اختيار فيديو اولا");
    //   return;
    // }
    addModuleVideo({
      ...data,
      module_id: module?.id,
    });
  }

  const [isChecked, setIsChecked] = useState(true);

  const handleCheckboxChange = () => {
    setIsChecked((prevChecked) => !prevChecked);
  };

  return (
    <>
      <Button
        sx={{
          bgcolor: "#b93232",
          display: "block",
          color: COLORS.WHITE,
          width: 150,
          height: 150,
          "&:hover": { bgcolor: "#b93232", opacity: "0.9" },
        }}
        onClick={() => {
          setOpen(true);
        }}
      >
        <VideocamIcon fontSize="large" />
        <Typography>{lang == "ar" ? "فيديو" : "Video"}</Typography>
      </Button>
      <Modal open={open} closeFn={() => setOpen(false)} maxWidth={"sm"}>
        <FormProvider {...methods}>
          <Form
            title={lang == "ar" ? " اضف فيديو" : "Add Video"}
            onSubmit={handleSubmit(submit)}
          >
            <Form.TextController
              register={register}
              registername="name"
              placeholder={
                lang == "ar" ? "ادخل اسم الفيديو " : "Enter video name "
              }
              errorMessage={errors?.name && errors?.name?.message}
            />

            {/* <label className={style.toggleContainer}>
              <input
                type="checkbox"
                checked={isChecked}
                onChange={handleCheckboxChange}
              />
               <div className={style.toggle}>
                <p
                  style={{
                    color: isDarkMode ? COLORS.SOFT_DARK : COLORS.WHITE,
                  }}
                >
                  {lang === "ar" ? "الجهاز" : "Device"}
                </p>
                <p
                  style={{
                    color: isDarkMode ? COLORS.SOFT_DARK : COLORS.WHITE,
                  }}
                >
                  {lang === "ar" ? "الرابط" : "Link"}
                </p>
              </div> 
            </label>*/}

            {isChecked ? (
              <Form.TextController
                register={register}
                registername="link"
                placeholder={
                  lang == "ar" ? "ادخل رابط الفيديو" : "Enter video link"
                }
                validationRules={{
                  required:
                    lang == "ar"
                      ? "رابط الفيديو مطلوب"
                      : "Video link is required",
                  validate: (value) => {
                    const youtubeRegex = /^(https?:\/\/)?(www\.)?(youtube\.com|youtu\.be)\/(watch\?v=|embed\/|v\/)?(\S+)?$/;
                    const m3u8Regex = /^https?:\/\/.*\.m3u8$/;
                    const isYoutubeLink = youtubeRegex.test(value);

                    if (!isYoutubeLink && !m3u8Regex) {
                      return lang == "ar"
                        ? "رابط الفيديو غير صالح"
                        : "Invalid video link";
                    }
                    return true;
                  },
                }}
                errorMessage={errors?.link && errors?.link?.message}
              />
            ) : (
              // <Form.DragAndDropController
              //   register={register}
              //   registername="video"
              //   setValue={setValue}
              //   errors={errors}
              //   working={isChecked}
              // />
              <></>
            )}

            <Form.ButtonController isPending={isPending} type="submit">
              {isPending ? (
                <HashLoader color={COLORS.WHITE} size={20} />
              ) : lang == "ar" ? (
                "رفع الفيديو"
              ) : (
                "Upload Video"
              )}
            </Form.ButtonController>
          </Form>
        </FormProvider>
      </Modal>
    </>
  );
};
