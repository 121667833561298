import { Close } from "@mui/icons-material";
import { Box, Button, Typography } from "@mui/material";
import React, { useEffect } from "react";
import HashLoader from "react-spinners/HashLoader";
import { useRemoveBanStudent } from "../../../hooks/useRemoveBanStudent";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { COLORS } from "@/constants/Colors/colors";
import { FONTS } from "@/constants/Fonts/fonts";

function RemoveBan({ id, closeNote, noteBook, refetch }) {
 const lang = useSelector((state) => state?.global?.lang);
  const globalState = useSelector((state) => state?.global);
  const { removeBanStudent, isPending } = useRemoveBanStudent({
    onSettled: close,
  });

  const handleRemove = () => {
    refetch();
    if (id) {
      removeBanStudent({ id: id });
      closeNote();
      refetch();
    }
  };

  return (
    <>
      <Box
        sx={{
          position: "fixed",
          top: "0",
          left: "0",
          width: "100%",
          height: "100%",
          bgcolor: "#000000ab",
          zIndex: "1501",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          transition: "0.3s",
          opacity: noteBook === "remove-ban" ? "1" : "0",
          visibility: noteBook === "remove-ban" ? "visible" : "hidden",
        }}
      >
        <Box
          sx={{
            bgcolor: globalState?.isDarkMode ? COLORS.DARK_GRAY : "white",
            padding: "30px",
            borderRadius: "10px",
            width: "400px",
            height: "auto",
            textAlign: "center",
            position: "relative",
            transition: "0.3s",
            opacity: noteBook === "remove-ban" ? "1" : "0",
            transform: noteBook === "remove-ban" ? "scale(1)" : "scale(0)",
          }}
        >
          <div
            className="close-btn"
            style={{
              position: "absolute",
              top: 10,
              right: 10,
              zIndex: 100,
            }}
          >
            <Close
              onClick={closeNote}
              sx={{
                color: "black",
                width: 30,
                height: 30,
                transition: "0.4s",
                cursor: "pointer",
                "&:hover": {
                  color: "#b0fe6a",
                },
              }}
            />
          </div>

          <Typography
            fontFamily={lang === 'ar' ? "Noor-bold" : FONTS.ENGLISH}
            fontSize={30}
            sx={{ fontWeight: "bold" }}
          >
            {lang == "ar"
              ? "هل تريد فك حظر هذا الطالب"
              : "Do you want to unblock this student?"}
          </Typography>

          <Box
            mt={5}
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alidngItems: "center",
            }}
          >
            <Button
              onClick={() => handleRemove()}
              sx={{
                padding: "7px 20px",
                width: "49%",
                bgcolor: "darkred",
                color: "white",
                trnsition: "0.4s",
                "&:hover": {
                  bgcolor: "red",
                },
              }}
            >
              {isPending ? (
                <HashLoader color="#ffffff" size={24} />
              ) : lang == "ar" ? (
                "أوافق"
              ) : (
                "Accept"
              )}
            </Button>
            <Button
              onClick={closeNote}
              sx={{
                padding: "7px 20px",
                width: "49%",
                bgcolor: "gray",
                color: "white",
                trnsition: "0.4s",
                "&:hover": {
                  bgcolor: "lightgray",
                },
              }}
            >
              {lang == "ar" ? "الغاء" : "Cancel"}
            </Button>
          </Box>
        </Box>
      </Box>
    </>
  );
}
RemoveBan.propTypes = {
  refetch: PropTypes.func,
  noteBook: PropTypes.bool,
  closeNote: PropTypes.func,
  id: PropTypes.number,
};

export default RemoveBan;
