import React from "react";
import Box from "@mui/material/Box";
import CloseIcon from "@mui/icons-material/Close";
import { Typography } from "@mui/material";
import { useRecoilState } from "recoil";
import QRCode from "react-qr-code";
import dayjs from "dayjs";

import { activeCodeAtom } from "../services/codeAtoms/RecoilCourseTypeAtom";
import { COLORS } from "@/constants/Colors/colors";
import CustomTableLoading from "@shared/Layout/TableRowLoading/CustomTableLoading";
import Row from "@shared/Layout/Row/Row";
import Column from "@shared/Layout/Column/Column";
import PropTypes from "prop-types";
import { useSelector } from 'react-redux';
export default function CodeDetails({ handleClose, course }) {
 const lang = useSelector((state) => state?.global?.lang);
  const [activeData] = useRecoilState(activeCodeAtom);

  return (
    <>
      {" "}
      {activeData && (
        <div
          // sx={{ marginX: "10px" }}
          style={{ width: "30%", background: COLORS.WHITE }}
        >
          {/* <CustomTableLoading width={"100%"} height={60} numOfCells={7} /> */}

          <Box>
            <Box
              sx={{
                padding: "10px",
                display: "flex",
                justifyContent: "start",
                alignItems: "center",
              }}
            >
              <CloseIcon
                fontSize="large"
                sx={{ marginX: "10px", cursor: "pointer" }}
                onClick={() => handleClose()}
              />
            </Box>
          </Box>
          <Row>
            <Column>
              <Box sx={{ mt: "30px" }}>
                <QRCode value={activeData.code} size={120} />
                <Typography color={"#2a2a2a"} variant="body1" fontSize={"20px"}>
                  {activeData.code}
                </Typography>
              </Box>

              <Box sx={{ mt: "55px", mb: "20px" }}>
                <Typography
                  variant="body1"
                  color={"#2a2a2a"}
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    fontFamily: "Noor-bold",
                    mt: "5px",
                  }}
                >
                  {lang == "ar" ? "رقم الطالب :" : "Student number"} &nbsp;
                  <Typography>
                    {activeData.subscription?.user?.phone ||
                      activeData.phone ||
                      "-"}
                  </Typography>
                </Typography>

                <Typography
                  variant="body1"
                  color={"#2a2a2a"}
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    fontFamily: "Noor-bold",
                    mt: "5px",
                  }}
                >
                  {lang == "ar" ? "تاريخ الإنشاء :" : "Date created"} &nbsp;
                  <Typography color={"#2a2a2a"}>
                    {dayjs(activeData?.created_at).format("YYYY-MM-DD")}
                  </Typography>
                </Typography>
                <Typography
                  variant="body1"
                  color={"#2a2a2a"}
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    fontFamily: "Noor-bold",
                    mt: "5px",
                  }}
                >
                  {lang == "ar" ? "تاريخ التفعيل :" : "Activation date"} &nbsp;
                  <Typography>
                    {" "}
                    {activeData.subscription
                      ? dayjs(activeData?.subscription.created_at).format(
                          "YYYY-MM-DD"
                        )
                      : activeData.updated_at
                      ? dayjs(activeData?.updated_at).format("YYYY-MM-DD")
                      : "-"}
                  </Typography>
                </Typography>
                <Typography
                  variant="body1"
                  color={"#2a2a2a"}
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    fontFamily: "Noor-bold",
                    mt: "5px",
                  }}
                >
                  {" "}
                  {lang == "ar" ? "المدة :" : "Duration"} &nbsp;
                  <Typography>
                    {" "}
                    {activeData?.days} {lang == "ar" ? "يوم" : "Day"}{" "}
                  </Typography>
                </Typography>
              </Box>

              {/* <Box sx={{ marginTop: "50px", marginBottom: "50px" }}>
                <Typography
                  variant="body1"
                  sx={{
                    my: "30px",
                    color: "#b0fe6a",
                    fontSize: "20px",
                    fontFamily: "Noor-bold",
                  }}
                >
                  {course.name}
                </Typography>
              </Box> */}
            </Column>
          </Row>
        </div>
      )}
    </>
  );
}

CodeDetails.PropTypes = {
  handleClose: PropTypes.bool,
  course: PropTypes.any,
};
