import React from 'react'

import signinStyle from "./signin.module.css"
import generalStyle from "../../layout/loginSignupLayout.module.css"
import { FaPhone } from "react-icons/fa6";
import { HiLockClosed } from "react-icons/hi2";
import ModeTuggle from '@/modules/settings/components/ModeTuggle';
import LangTuggle from '@/modules/settings/components/LangTuggle/LangTuggle';
import { useSelector } from 'react-redux';
import { COLORS } from '@/constants/Colors/colors';
import { useLogin } from "../../../../hooks/login.hook";
import { useForm, FormProvider } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import ColorButton from "@/components/Buttons/ColorButton/ColorButton";
import { loginSchema } from "../../../../shema/login.schema";
import { FONTS } from '@/constants/Fonts/fonts';
import BorderedInput from '@/components/Form/BorderedInput/BorderedInput';
import ColorCheckBox from '@/components/Form/ColorCheckBox/ColorCheckBox';
import { useEffect } from 'react';
import { useState } from 'react';




const Signin = ({ signIn, onClick }) => {
  const globalState = useSelector((state) => state?.global);
  const lang = globalState?.lang;
  const isDarkMode = !globalState?.isDarkMode;

  const { isPending, onSubmit } = useLogin();

  const methods = useForm({
    resolver: zodResolver(loginSchema),
  });
  const { handleSubmit } = methods;


  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 600);

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth < 601);
    };

    handleResize();
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);


  return (
    <div className={`${generalStyle.signInContainer} ${signinStyle.container} ${!signIn ? signinStyle.slide : ""}`}
      style={{
        backgroundColor: isDarkMode ? COLORS.WHITE : COLORS.SOFT_DARK,
        direction: lang === 'ar' ? 'rtl' : 'ltr',
        fontFamily: lang === 'en' ? FONTS.ENGLISH : '',
        left: isSmallScreen ? (lang === 'ar' ? "initial" : "") : ''
      }} >

      <span className={generalStyle.moods}>
        {/* <ModeTuggle /> */}
        <LangTuggle />
      </span>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)} style={{ backgroundColor: isDarkMode ? COLORS.WHITE : COLORS.SOFT_DARK }}>
          <div className={generalStyle.content}>
            <p>{lang === 'ar' ? "ابدأ التعلم" : "start learning"}</p>
            <h1>{lang === 'ar' ? "تسجيل الدخول." : "Sign In."}</h1>
            <p>{lang === 'ar' ? "ليس لديك حساب؟" : "Don't have an account?"} <span onClick={onClick}>{lang === 'ar' ? "اشترك الآن" : "Sign Up"}</span>.</p>
          </div>

          <BorderedInput
            placeholder={lang == "ar" ? "رقم الهاتف" : "Phone number"}
            registerName="phone"
            Icon={FaPhone}
            lang={lang}
            isDarkMode={isDarkMode}
            color={COLORS.PRIMARY.MAIN}
          />

          <BorderedInput
            placeholder={lang == "ar" ? "كلمة المرور" : "Password"}
            registerName="password"
            type="password"
            Icon={HiLockClosed}
            lang={lang}
            isDarkMode={isDarkMode}
            color={COLORS.PRIMARY.MAIN}
          />


          <div className={signinStyle.rememberMe}>
            <ColorCheckBox
              isDarkMode={isDarkMode}
              lang={lang}
              color={COLORS.PRIMARY.MAIN}
              label={lang == "ar" ? "تذكرني دائما" : "Remember me"}
            />
          </div>

          <ColorButton
            width={"100%"}
            colorType="submit"
            type="submit"
            isLoading={isPending}
            sx={{ color: isDarkMode ? COLORS.WHITE : COLORS.SOFT_DARK, textTransform: 'capitalize' }}
          >
            {lang == "ar" ? "تسجيل الدخول" : "Sign In"}
          </ColorButton>
        </form>
      </FormProvider>
    </div>
  )
}

export default Signin