import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";
import React from "react";
import ColorButton from "@shared/Buttons/ColorButton/ColorButton";
import CloseIcon from "@mui/icons-material/Close";
import useDeleteVideo from "./useDeleteVideo";
import useDeleteHomeWork from "./useDeleteHomeWork";
import useDeleteExam from "./useDeleteExam";
import useDeleteDrive from "./useDeleteDrive";
import useDeleteModule from "../ModuleDetailsCollapse/hook/useDeleteModule";
import { useSelector } from 'react-redux';
import { FONTS } from "@/constants/Fonts/fonts";
import { COLORS } from "@/constants/Colors/colors";
export default function DeleteDialog({
  isConfirmOpen,
  setIsConfirmOpen,
  deleteName,
  deleteData,
  confirmMessage,
  id,
}) {
  const language = useSelector((state) => state?.global);
  const lang = language?.lang;
  const { deleteVideo, isPending } = useDeleteVideo({
    id: deleteData?.id,
    lang,
  });
  const { deleteExam, isExamLoading } = useDeleteExam({
    id: deleteData?.id,
    lang,
  });
  const { deleteDrive, isDriveLoading } = useDeleteDrive({
    id,
    deleteName,
    lang,
  });
  const { deleteHomeWork, isPending: isLoading } = useDeleteHomeWork({
    id: deleteData?.id,
    lang,
  });
  const { deleteModule, isPending: isModuleLoading } = useDeleteModule({
    id: deleteData?.id,
    lang,
  });

  return (
    <Dialog
      maxWidth="sm"
      fullWidth
      onClose={() => setIsConfirmOpen(false)}
      open={isConfirmOpen}
    >
      <DialogTitle sx={{ borderTop: "#b0fe6a 5px solid" }}>
        <Button onClick={() => setIsConfirmOpen(false)}>
          <CloseIcon />
        </Button>
      </DialogTitle>{" "}
      <DialogContent
        sx={{
          borderBottom: "#b0fe6a 5px solid",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Typography my={4} fontSize={"24px"}>
          {confirmMessage || ""} {deleteName || ""}
        </Typography>
        <Box sx={{ display: "flex", justifyContent: "center", gap: 2 }}>
          <ColorButton
            sx={{
              bgcolor: COLORS.SECONDARY.MAIN,
              transition: "all 0.3s",
            }}
            variant="contained"
            onClick={() => {
              setIsConfirmOpen(false);

              switch (true) {
                case confirmMessage === "هل انت متأكد من حذف الفيديو" || confirmMessage === "Are you sure you want to delete the video?":
                  deleteVideo(deleteData?.id);
                  break;

                case confirmMessage === (lang === "ar" ? "هل انت متأكد من حذف الملف" : "Are you sure you want to delete the File?"):
                  deleteDrive({ id, deleteName });
                  break;

                case confirmMessage === (lang === "ar" ? "هل انت متأكد من حذف الواجب" : "Are you sure you want to delete the Homework?"):
                  deleteHomeWork(deleteData?.id);
                  break;

                case confirmMessage === (lang === "ar" ? "هل انت متأكد من حذف الامتحان" : "Are you sure you want to delete the Exam?"):
                  deleteExam(deleteData?.id);
                  break;

                case confirmMessage === (lang === "ar" ? "هل انت متأكد من حذف الحصه" : "Are you sure you want to delete the Lesson?"):
                  deleteModule(deleteData?.id);
                  break;

                default:
                  console.error("No matching case for confirmMessage");
                  break;
              }
            }}
          >
            {lang == "ar" ? "تأكيد" : "Confirm"}
          </ColorButton>
          <ColorButton
            variant="contained"
            sx={{
              bgcolor: COLORS.DANGER,
              transition: "all 0.3s",
            }}
            onClick={() => setIsConfirmOpen(false)}
          >
            {" "}
            {lang == "ar" ? "رجوع" : "Cancel"}
          </ColorButton>
        </Box>
      </DialogContent>
    </Dialog >
  );
}
