import { COLORS } from "@/constants/Colors/colors";
import { Button, InputAdornment, TextField } from "@mui/material";
import useCustomMutation from "@/hooks/useCustomMutation";
import Modal from "@shared/Modal/Modal";
import { notifySuccess } from "@shared/Toast/toast";
import { useQueryClient } from "@tanstack/react-query";
import React, { useState } from "react";
import { HashLoader } from "react-spinners";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";

export const AddBar = ({ open, setOpen }) => {
  const lang = useSelector((state) => state?.global?.lang);
  const [message, setMessage] = useState("");
  const [submitted, setSubmitted] = useState(false);
  const queryClient = useQueryClient();
  const { mutate, isPending } = useCustomMutation({
    url: `bar/createMessage`,
    onSuccess: () => {
      setOpen(false);
      notifySuccess("تم إضافة البانر بنجاح");
      queryClient.invalidateQueries(["bar"]);
    },
  });
  return (
    <Modal open={open} closeFn={() => setOpen(false)}>
      <TextField
        label={lang == "ar" ? "العنوان" : "Title"}
        type="text"
        value={message}
        onChange={(e) => {
          setMessage(e.target.value);
        }}
        error={!message && submitted}
        helperText={
          !message && submitted && lang == "ar"
            ? "يجب إضافة عنوان للبانر"
            : "You must add a title to the banner"
        }
        sx={{
          marginTop: 2,
          width: "70%",
          "& .MuiFormLabel-root.Mui-focused": {
            color: "#b0fe6a",
          },
          "& label": {
            left: lang == "ar" ? "unset" : "0.1rem",
            right: lang == "ar" ? "1.75rem" : "unset",
            transformOrigin: lang == "ar" ? "right" : "left",
            fontSize: "0.8rem",
            color: "#b0fe6a",
          },
          "& legend": {
            textAlign: lang == "ar" ? "right" : "left",
            fontSize: "0.6rem",
          },
          direction: lang == "ar" ? "rtl" : "ltr",
        }}
        InputProps={{
          startAdornment: <InputAdornment position="end"></InputAdornment>,
        }}
      />
      <Button
        onClick={() => {
          setSubmitted(true);
          if (!message) return;
          mutate({
            body: message,
          });
        }}
        sx={{
          bgcolor: COLORS.PRIMARY.MAIN,
          color: COLORS.WHITE,
          marginTop: 2,
          "&:hover": { bgcolor: COLORS.PRIMARY.MAIN },
        }}
      >
        {isPending ? (
          <HashLoader color={COLORS.WHITE} size={20} />
        ) : lang == "ar" ? (
          "أضف"
        ) : (
          "Add"
        )}
      </Button>
    </Modal>
  );
};

AddBar.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
};
