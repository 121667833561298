import { Box, Checkbox, FormControlLabel, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import axios from "axios";
import { useMutation } from "@tanstack/react-query";
import { useDropzone } from "react-dropzone";
import { ClipLoader } from "react-spinners";
import { notifyError, notifySuccess } from "@shared/Toast/toast";
import { api_url } from "@/constants/base_url";
import { SharedTextField } from "@shared/SharedTextField/SharedTextField";
import ColorButton from "@shared/Buttons/ColorButton/ColorButton";
import { currentUser } from "@shared/Helpers/encryptionHelpers";
// import { setOrder } from "../OrderStore/OrderSlice";

export default function EditBookOrders({ closeEditing, refetch, bookData }) {
  //   const bookDataRedux = useSelector((state) => state.order);
  //   const dispatch = useDispatch();
  useEffect(() => {
    if (bookData?.length != 0) {
      localStorage.setItem("activeBook", JSON.stringify(bookData));
    }
  }, [bookData]);
  //   console.log(bookDataRedux, "bookData from edit");

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
  } = useForm({
    values: {
      available_amount: bookData?.available_amount,
      image: bookData?.image,
      isOpenForReservation: bookData?.isOpenForReservation,
      price: bookData?.price,
      title: bookData?.title,
    },
  });

  const [isOpenForReservationState, setIsOpenForReservationState] = useState(
    bookData?.isOpenForReservation == "1" ? true : false
  );
  const [imageEditSrc, setImageEditSrc] = useState({});
  const [updateImage, setUpdateImage] = useState({});

  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
    maxFiles: 1,
    accept: {
      "image/*": [".png", ".jpg", ".jpeg"],
    },
    onDrop: (acceptedFile) => {
      setImageEditSrc({
        url: URL.createObjectURL(acceptedFile[0]),
        file: acceptedFile[0],
      });
      setUpdateImage({
        url: URL.createObjectURL(acceptedFile[0]),
        file: acceptedFile[0],
      });
    },
  });

  const { mutate, isLoading, error, isError } = useMutation({
    mutationFn: (formData) =>
      axios.post(`${api_url}book/updateBook`, formData, {
        headers: {
          // 'Content-Type': 'application/json',
          Authorization: `Bearer ${currentUser()?.token}`,
        },
      }),
    onSuccess: (formData) => {
      setImageEditSrc({});
      setUpdateImage({});
      refetch();
      notifySuccess("تم تعديل بيانات الكتاب");
      closeEditing();
    },
    onError: () => {
      notifyError("حدث خطأ اثناء تنفيذ المهمة");
    },
  });

  function onSubmit(data) {
    const formData = new FormData();
    formData.append("book_id", bookData?.id);
    formData.append("title", data?.title);
    formData.append("price", data?.price);
    formData.append("available_amount", data?.available_amount);
    formData.append("isOpenForReservation", isOpenForReservationState ? 1 : 0);
    if (updateImage?.file) {
      formData.append("image", updateImage?.file);
    }
    // else {
    //   formData.append("image", bookData?.img);
    // }
    mutate(formData);

    // dispatch(
    //   setOrder({
    //     id: bookData?.id,
    //     img: updateImage?.file ? updateImage?.file : bookData.image,
    //     isOpenForReservation: isOpenForReservationState ? 1 : 0,
    //     price: data?.price,
    //     teacherName:
    //       selectedTeacher != bookDataRedux?.teacherName
    //         ? selectedTeacher[0]
    //         : bookDataRedux?.teacherName,
    //     teacher_id: bookDataRedux?.teacher_id,
    //     title: data?.title,
    //     available_amount: data?.available_amount,
    //   })
    // );
    localStorage.setItem(
      "reduxState",
      JSON.stringify({
        id: bookData?.id,
        img: updateImage?.file ? updateImage?.file : bookData.image,
        isOpenForReservation: bookData?.isOpenForReservationState ? 1 : 0,
        price: data?.price,
        // teacherName:
        //   selectedTeacher != bookDataRedux?.teacherName
        //     ? selectedTeacher[1]
        //     : bookDataRedux?.teacherName,
        // teacher_id: bookDataRedux?.teacher_id,
        title: data?.title,
        available_amount: data?.available_amount,
      })
    );
  }
  return (
    <form
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        width: "100%",
      }}
      onSubmit={handleSubmit(onSubmit)}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "",
          padding: "20px",
          borderRadius: "10px",
        }}
      >
        <Box
          sx={{
            marginBottom: "40px",
            textAlign: "center",
            position: "relative",
          }}
        >
          <Box
            sx={{
              position: "relative",
              width: "100%",
              marginLeft: "10px",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <div
              {...getRootProps()}
              style={{
                border: "1px solid #b0fe6a",
                padding: "20px 10px 5px 10px",
                borderRadius: "10px",
                position: "relative",
              }}
            >
              <span
                style={{
                  position: "absolute",
                  top: "-20px",
                  right: "40px",
                  fontSize: "15px",
                  color: "#b0fe6a",
                  background: "#F5F5F5",
                  padding: "5px 20px",
                }}
              >
                صورة الكتاب
              </span>

              <img
                src={
                  imageEditSrc?.url ||
                  `${api_url.slice(0, -7)}${bookData.image}`
                }
                style={{
                  width: "400px",
                  height: "300px",
                  cursor: "pointer",
                  borderRadius: "10px",
                }}
                alt=""
              />
            </div>
            <input {...getInputProps()} />
          </Box>
        </Box>

        <Box
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            gap: "20px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginBottom: "5px",
              gap: "20px",
            }}
          >
            <SharedTextField
              //   dark
              register={register("title")}
              placeholder="ادخل العنوان "
              label="العنوان"
              extra={{
                error: errors.title,
                helperText: errors.title?.message,
              }}
            />

            <SharedTextField
              //   dark
              register={register("price")}
              placeholder="ادخل السعر "
              label="السعر"
              extra={{
                error: errors.price,
                helperText: errors.price?.message,
              }}
            />
          </Box>

          <SharedTextField
            //   dark
            register={register("available_amount")}
            placeholder="ادخل الكمية المتاحة "
            label="الكمية المتاحة"
            extra={{
              error: errors.available_amount,
              helperText: errors.available_amount?.message,
            }}
          />

          <FormControlLabel
            sx={{
              my: 2,
              width: "100%",
              display: "flex",
              justifyContent: "start",
              alignItems: "center",
              mr: -1,
              color: "#b0fe6a",
            }}
            control={
              <Checkbox
                onClick={() => {
                  setIsOpenForReservationState((x) => !x);
                }}
                checked={isOpenForReservationState}
              />
            }
            label="متاح للحجز"
          />
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          gap: "10px",
          marginRight: "10px",
        }}
      >
        <ColorButton
          disabled={isLoading}
          variant="contained"
          type="submit"
          sx={{
            bgcolor: "#b0fe6a",
            px: "30px",
          }}
        >
          {isLoading ? <ClipLoader color="#000" size={20} /> : "تعديل"}
        </ColorButton>
        <ColorButton
          disabled={isLoading}
          onClick={() => {
            reset({
              available_amount: bookData?.available_amount,
              img: bookData?.image,
              isOpenForReservation: bookData?.isOpenForReservation,
              price: bookData?.price,
              title: bookData?.title,
              //   teacherName: bookDataRedux?.teacherName,
              //   teacher_id: bookDataRedux?.teacher_id,
            });
          }}
          variant="text"
          sx={{
            color: "#b0fe6a",
            px: "30px",
            bgcolor: "#fff",
            "&:hover": {
              color: "#fff",
              bgcolor: "#b0fe6a",
            },
          }}
        >
          استعادة
        </ColorButton>
        <ColorButton
          disabled={isLoading}
          onClick={() => {
            closeEditing();
          }}
          variant="text"
          sx={{
            color: "#b0fe6a",
            px: "30px",
            bgcolor: "#fff",
            "&:hover": {
              color: "#fff",
              bgcolor: "#b0fe6a",
            },
          }}
        >
          اغلاق
        </ColorButton>
      </Box>
    </form>
  );
}
