import { COLORS } from "@/constants/Colors/colors";
import useCustomQuery from "@/hooks/useCustomQuery";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { HashLoader } from "react-spinners";
import { Box, Typography } from "@mui/material";
import { VideoPlayer } from "./VideoPlayer/VideoPlayer";
import { useSelector } from "react-redux";
import videoStyle from "./CourseVideo.module.css"
import { FONTS } from "@/constants/Fonts/fonts";
import { api_url } from '@/constants/base_url';
import CustomVideoPlayer from "./VideoPlayer/CustomVideoPlayer";
import { useGetData } from '@/hooks/useGetData';

export const CourseVideo = ({ videos }) => {
  const globalState = useSelector((state) => state?.global);
  const lang = globalState?.lang;
  const [lessonId, setLessonId] = useState(videos?.[0]?.id || 0);
  const [videoTitle, setVideoTitle] = useState("");
  const youtubeRegex = /^(https?\:\/\/)?(www\.)?(youtube\.com|youtu\.be)\/.+$/;

  console.log("videos", videos.map((x) => `${x.id}`))

  useEffect(() => {
    if (videos?.length > 0) {
      setVideoTitle(videos[0].name);
    }
  }, [videos, setVideoTitle]);


  const { id, mid } = useParams();

  const {
    data: youtubeLesson,
    isPending: youtubeLessonLoading,
  } = useCustomQuery({
    url: `${api_url}lesson/${lessonId}`,
    data: { lesson_id: lessonId },
    gcTime: 0,
    staleTime: 0,
    key: ["lesson", id, mid, lessonId, lang],
    lang,
    enabled: !!mid && lessonId !== 0,
  });

  const isYoutube = youtubeRegex.test(youtubeLesson?.data?.link);
  console.log("youtube link:", youtubeLesson?.data?.link)


  const spinner = (
    <div
      style={{
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        marginTop: "30px",
        marginBottom: "30px",
      }}
    >
      <HashLoader color={COLORS.PRIMARY.MAIN} />
    </div>
  );



  const content = (
    <>
      <div className={`${videoStyle.container} ${lang === 'ar' ? videoStyle.arabicContainer : ""}`}>
        {videos?.length > 0 &&
          videos?.map(({ name, id }, index) => (
            <div
              className={`${videoStyle.videoCard} ${id == lessonId ? videoStyle.activeVideoCard : ""}`}
              onClick={() => {
                setLessonId(id);
                setVideoTitle(name);
              }}
              key={id}
            >
              <p className={videoStyle.index}>
                {index < 9 ? `0${index + 1}` : index + 1}
              </p>
              <p className={videoStyle.videoTitle}>{name}</p>
            </div>
          ))}
      </div>
    </>
  );


  return (
    <>
      <Box sx={{ width: "100%" }}>
        {videos?.length == 0 ? (
          <Typography
            height={'50vh'}
            fontSize={24}
            fontWeight={"bold"}
            fontFamily={lang === 'en' ? FONTS.ENGLISH : ""}
            textAlign={"center"}
            sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}
          >
            {lang == "ar" ? "لا يوجد فيديوهات" : "There is no videos"}
          </Typography>) :
          youtubeLessonLoading ? (spinner) : isYoutube ? <VideoPlayer VideoLink={youtubeLesson?.data?.link} /> : <CustomVideoPlayer VideoLink={youtubeLesson?.data?.link}/>
        }


      </Box>
      <h1 className={videoStyle.mainTitle}>
        {videoTitle}
      </h1>
      {content}
    </>
  );
};
