import React, { useEffect } from 'react'
import signupStyle from "./signup.module.css"
import generalStyle from "../../layout/loginSignupLayout.module.css"
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useMutation } from "@tanstack/react-query";
import axios from "axios";
import { api_url } from "@/constants/base_url";
import { IoPerson } from "react-icons/io5";
import { HiLockClosed } from "react-icons/hi2";
import { IoIosMail } from "react-icons/io";
import { FaPhone } from "react-icons/fa6";
import { FaLocationDot } from "react-icons/fa6";
import { useState } from 'react';
import { COLORS } from '@/constants/Colors/colors';
import ModeTuggle from '@/modules/settings/components/ModeTuggle';
import LangTuggle from '@/modules/settings/components/LangTuggle/LangTuggle';
import { FONTS } from '@/constants/Fonts/fonts';
import { useSelector } from 'react-redux';
import ColorButton from "@/components/Buttons/ColorButton/ColorButton";
import { CustomBorderedInput } from '@/components/Form/BorderedInput/BorderedInput';
import { FormProvider } from 'react-hook-form';
import { getNameValidationRules, getStudentNumberValidationRules, getParentNumberValidationRules, getCityValidationRules, getEmailValidationRules, getPasswordValidationRules, getPasswordConfirmationValidationRules } from "./ValidationRules"
import { notifySuccess } from '@shared/Toast/toast';

const governorates = [
  { value: "Alexandria", label: "الإسكندرية" },
  { value: "Aswan", label: "أسوان" },
  { value: "Asyut", label: "أسيوط" },
  { value: "Beheira", label: "البحيرة" },
  { value: "Beni Suef", label: "بني سويف" },
  { value: "Cairo", label: "القاهرة" },
  { value: "Dakahlia", label: "الدقهلية" },
  { value: "Damietta", label: "دمياط" },
  { value: "Faiyum", label: "الفيوم" },
  { value: "Gharbia", label: "الغربية" },
  { value: "Giza", label: "الجيزة" },
  { value: "Ismailia", label: "الإسماعيلية" },
  { value: "Kafr El Sheikh", label: "كفر الشيخ" },
  { value: "Luxor", label: "الأقصر" },
  { value: "Matrouh", label: "مطروح" },
  { value: "Minya", label: "المنيا" },
  { value: "Monufia", label: "المنوفية" },
  { value: "New Valley", label: "الوادي الجديد" },
  { value: "North Sinai", label: "شمال سيناء" },
  { value: "Port Said", label: "بورسعيد" },
  { value: "Qalyubia", label: "القليوبية" },
  { value: "Qena", label: "قنا" },
  { value: "Red Sea", label: "البحر الأحمر" },
  { value: "Sharqia", label: "الشرقية" },
  { value: "Sohag", label: "سوهاج" },
  { value: "South Sinai", label: "جنوب سيناء" },
];

const Signup = ({ signIn, onClick }) => {
  const methods = useForm({
    defaultValues: {
      name: "",
      email: "",
      phone: "",
      parent_phone: "",
      city: "",
      password: "",
      password_confirmation: "",
    },
  });

  const [successfullyRegistered, setSuccessfullyRegistered] = useState(false);


  const { mutate, isError, error, isPending } = useMutation({
    mutationFn: (data) => {
      return axios.post(`${api_url}register`, data, {
        headers: {
          "Content-Type": "application/json",
        },
      });
    },
    onSuccess: (data) => {
      console.log(data);
      notifySuccess(
        lang == "ar" ? "تم انشاء الحساب بنجاح" : "Account Created Succefully"
      );
      onClick();
    },
  });

  const onSubmit = (data) => {
    mutate(data);
  };

  if (isError) {
    console.log("axios error", error);
  }

  // ----------- hooks ----------
  const globalState = useSelector((state) => state?.global);
  const lang = globalState?.lang;
  const isDarkMode = !globalState?.isDarkMode;

  const navigate = useNavigate();
  const password = methods.watch("password", "");
  const selectedCity = methods.watch("city");
  const [passType, setPassType] = useState("password");


  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 600);

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth < 601);
    };

    handleResize();
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);


  return (
    <div className={`${generalStyle.signUpContainer} ${signupStyle.container} ${!signIn ? signupStyle.slide : ""}`} style={{ backgroundColor: isDarkMode ? COLORS.WHITE : COLORS.SOFT_DARK, direction: lang === 'ar' ? 'rtl' : 'ltr', fontFamily: lang === 'en' ? FONTS.ENGLISH : '' ,
      left: isSmallScreen ? (lang === 'ar' ? "initial" : "") : '',
      margin: isSmallScreen ? (lang==='ar' ? '0 -6% 0 0' : "") : ""
    }} >
      <span className={generalStyle.moods} style={{ backgroundColor: isDarkMode ? COLORS.WHITE : COLORS.SOFT_DARK}}>
        {/* <ModeTuggle /> */}
        <LangTuggle />
      </span>

      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)} style={{
          backgroundColor: isDarkMode ? COLORS.WHITE : COLORS.SOFT_DARK, transform: 'scale(0.8)', left: !isSmallScreen ? (lang === 'ar' ? "initial" : "initial") : '0%'
        }}>
          <div className={generalStyle.content}>
            <p>{lang === 'ar' ? "انضم إلينا" : "join us"}</p>
            <h1>{lang === 'ar' ? "انشاء حساب" : "Sign Up."}</h1>
            <p>{lang === 'ar' ? "بالفعل لديك حساب؟" : "Already have an account?"} <span onClick={onClick}>{lang === 'ar' ? "تسجيل الدخول" : "Sign In"}</span>.</p>
          </div>

          <CustomBorderedInput
            errors={methods.formState.errors.name}
            isDarkMode={isDarkMode}
            color={COLORS.SECONDARY.MAIN}
            Icon={IoPerson}
          >
            <input
              type="text"
              placeholder={lang == "ar" ? "الاسم بالكامل" : "Full name"}

              {...methods.register("name", getNameValidationRules(lang))}
              style={{ color: isDarkMode ? COLORS.BLACK : COLORS.WHITE }}
            />
          </CustomBorderedInput>

          {error?.response?.data?.errors?.phone?.[0] ===
            "The phone has already been taken." ? (
            <p className={generalStyle.errorMessage} style={{
              fontFamily: lang === 'ar' ? FONTS.BOLD : FONTS.ENGLISH
            }}>
              {lang == "ar"
                ? "رقم الطالب مستخدم من قبل"
                : "Student number is already in use"}
            </p>
          ) : (
            ""
          )}

          <div className={signupStyle.inputContainer}>

            <CustomBorderedInput
              errors={methods.formState.errors.phone}
              isDarkMode={isDarkMode}
              color={COLORS.SECONDARY.MAIN}
              Icon={FaPhone}
            >
              <input
                type="text"
                placeholder={lang == "ar" ? "رقم الطالب" : "Student Number"}
                {...methods.register("phone", getStudentNumberValidationRules(lang))}
                style={{ color: isDarkMode ? COLORS.BLACK : COLORS.WHITE }}
              />
            </CustomBorderedInput>

            <CustomBorderedInput
              errors={methods.formState.errors.parent_phone}
              isDarkMode={isDarkMode}
              color={COLORS.SECONDARY.MAIN}
              Icon={FaPhone}
            >
              <input
                type="text"
                placeholder={lang == "ar" ? " رقم ولي الامر" : "Parent Number"}

                {...methods.register("parent_phone", getParentNumberValidationRules(lang))}
                style={{ color: isDarkMode ? COLORS.BLACK : COLORS.WHITE }}
              />
            </CustomBorderedInput>

          </div>

          <CustomBorderedInput
            errors={methods.formState.errors.city}
            isDarkMode={isDarkMode}
            color={COLORS.SECONDARY.MAIN}
            Icon={FaLocationDot}
          >
            <select
              {...methods.register("city", getCityValidationRules(lang, selectedCity))}
            >
              <option value="" disabled selected>
                {lang == "ar" ? "اختر المحافظة" : "Choose a city"}
              </option>

              {governorates?.map((i, index) => {
                return (
                  <>
                    <option key={index} value={i.value} style={{ color: COLORS.BLACK }}>
                      {lang === 'ar' ? i.label : i.value}
                    </option>
                  </>
                );
              })}
            </select>
          </CustomBorderedInput>

          {error?.response?.data?.errors?.email?.[0] ===
            "The email has already been taken." ||
            error?.response?.status === 400 ? (
            <p
              className={generalStyle.errorMessage} style={{
                fontFamily: lang === 'ar' ? FONTS.BOLD : FONTS.ENGLISH
              }}>
              {lang == "ar"
                ? " هذا الايميل مستخدم من قبل"
                : "This email has already been used"}
            </p>
          ) : (
            ""
          )}

          <CustomBorderedInput
            errors={methods.formState.errors.email}
            isDarkMode={isDarkMode}
            color={COLORS.SECONDARY.MAIN}
            Icon={IoIosMail}
          >
            <input
              type="text"
              placeholder={lang == "ar" ? "البريد الالكتروني" : "Email"}
              {...methods.register("email", getEmailValidationRules(lang))}
              style={{ color: isDarkMode ? COLORS.BLACK : COLORS.WHITE }}
            />

          </CustomBorderedInput>

          <div className={signupStyle.inputContainer}>
            <CustomBorderedInput
              type={passType}

              errors={methods.formState.errors.password}
              isDarkMode={isDarkMode}
              color={COLORS.SECONDARY.MAIN}
              Icon={HiLockClosed}
            >
              <input
                type='password'
                placeholder={lang == "ar" ? "كلمة السر" : "Password"}

                {...methods.register("password", getPasswordValidationRules(lang))}
                style={{ color: isDarkMode ? COLORS.BLACK : COLORS.WHITE }}
              />
            </CustomBorderedInput>

            <CustomBorderedInput
              type={passType}
              setPassType={setPassType}

              errors={methods.formState.errors.password_confirmation}
              isDarkMode={isDarkMode}
              color={COLORS.SECONDARY.MAIN}
              Icon={HiLockClosed}
            >
              <input
                type='password'
                placeholder={
                  lang == "ar" ? "تأكيد كلمة السر" : "Confirm Password"
                }

                {...methods.register("password_confirmation", getPasswordConfirmationValidationRules(lang, password))}
                style={{ color: isDarkMode ? COLORS.BLACK : COLORS.WHITE }}
              />
            </CustomBorderedInput>
          </div>

          <ColorButton
            width={"100%"}
            colorType="submit"
            type="submit"
            isLoading={isPending}
            sx={{ color: isDarkMode ? COLORS.WHITE : COLORS.SOFT_DARK, textTransform: 'capitalize' }}
          >
            {lang == "ar" ? "الإشتراك" : "Sign Up"}
          </ColorButton>
        </form>
      </FormProvider>


    </div >
  );
};

export default Signup;