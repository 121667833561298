import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import useCustomQuery from "@/hooks/useCustomQuery";
import { useState } from "react";
import LightbulbIcon from "@mui/icons-material/Lightbulb";
import ColorButton from "@shared/Buttons/ColorButton/ColorButton";
import CloseIcon from "@mui/icons-material/Close";
import NoItemsText from "@shared/NoItemText/NoItemText";
import { formatter } from "@shared/Helpers/formatter";

export default function ExamStudentsData({ module }) {
  const [open, setOpen] = useState(false);

  const {
    data,
    isPending: isloading,
    isError,
  } = useCustomQuery({
    method: "post",
    url: `exam/getLessonExamGrades`,
    key: ["exam data", module?.id],
    retry: false,
    data: {
      lesson_module_id: module?.id,
      all: 1,
    },
  });

  return (
    <>
      <IconButton
        onClick={() => setOpen(true)}
        aria-label="delete"
        size="small"
        sx={{
          bgcolor: "#e8cf15",
          "&:hover": {
            bgcolor: "#baa60f",
          },
        }}
      >
        <LightbulbIcon
          sx={{
            fontSize: "20px",
          }}
        />
      </IconButton>
      <Dialog
        maxWidth="md"
        fullWidth
        onClose={() => setOpen(false)}
        open={open}
      >
        <DialogTitle sx={{ borderTop: "#b0fe6a 5px solid" }}>
          <Button onClick={() => setOpen(false)}>
            <CloseIcon />
          </Button>
        </DialogTitle>{" "}
        <DialogContent
          sx={{
            borderBottom: "#b0fe6a 5px solid",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <DownloadExamsData
              data={data?.data?.students}
              moduleName={module?.name}
            />
            <Typography sx={{ my: 1, fontSize: "20px" }}>
              عدد الممتحنين : {formatter.format(data?.data?.total_exam_takes)}
            </Typography>
          </Box>
          <TableContainer>
            <Table sx={{ direction: "rtl" }} aria-label="simple table">
              <TableHead sx={{ bgcolor: "#b0fe6a" }}>
                <TableRow sx={{ color: "white" }}>
                  <TableCell color="white" align="center">
                    اسم الطالب
                  </TableCell>
                  <TableCell color="white" align="center">
                    رقم الطالب
                  </TableCell>
                  <TableCell color="white" align="center">
                    عدد المحاولات
                  </TableCell>
                  <TableCell color="white" align="center">
                    الدرجة
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data?.data?.students?.length !== 0 ? (
                  data?.data?.students?.map((student) => (
                    <TableRow key={student.id}>
                      <TableCell align="center">{`${student.name}`}</TableCell>
                      <TableCell align="center">{student.phone}</TableCell>
                      <TableCell align="center">
                        {student?.numberOfFails === null
                          ? "-"
                          : student?.numberOfFails}
                      </TableCell>
                      <TableCell align="center">
                        {student?.user_grade || "-"}
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <NoItemsText text="لا يوجد بيانات" />
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </DialogContent>
      </Dialog>
    </>
  );
}

function DownloadExamsData({ data, moduleName }) {
  function convertArrayToCSV(array) {
    if (!Array.isArray(array)) {
      return "";
    }

    if (array.length === 0) {
      return "";
    }

    if (typeof array[0] === "object") {
      const csvRows = [];
      const headers = [
        "name",
        "phone",
        "grade",
        "status",
        "number of right answers",
        "number of fails",
      ];
      console.log("excel headers", headers);
      csvRows.push(headers.join(","));

      for (const row of array) {
        console.log("row", row);

        const values = {
          name: `${row.name}`,
          phone: row.phone,
          grade: row.exam_answers?.[0]?.grade
            ? `${row.exam_answers?.[0]?.grade} / ${row?.grade}`
            : "-",
          status: row.exam_answers?.[0]?.status,
          number_of_right_answers: row?.exam_answers?.[0]?.numberOfRightAnswers,
          numberOfFails: row?.exam_answers?.[0]?.numberOfFails,
        };

        console.log("values", values);
        csvRows.push(Object.values(values).join(","));
      }
      return csvRows.join("\n");
    }
  }

  const [downloadExcel] = useState(true);

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "start",
        alignItems: "center",
        my: 1,
      }}
    >
      <ColorButton
        sx={{
          color: "white",
        }}
        onClick={() => {
          console.log(data);
          if (data instanceof Array) {
            if (downloadExcel) {
              const csvData = convertArrayToCSV(data);
              console.log("csvData", csvData);
              const blob = new Blob([csvData], {
                type: "text/csv;charset=utf-8;",
              });
              const filename =
                `${moduleName}_exam_data` || "students_exams.csv";
              if (navigator.msSaveBlob) {
                // For Microsoft Edge and Internet Explorer
                navigator.msSaveBlob(blob, filename);
              } else {
                // For modern browsers
                const link = document.createElement("a");
                if (link.download !== undefined) {
                  const url = URL.createObjectURL(blob);
                  link.setAttribute("href", url);
                  link.setAttribute("download", filename);
                  link.style.visibility = "hidden";
                  document.body.appendChild(link);
                  link.click();
                  document.body.removeChild(link);
                }
              }
            }
          }
        }}
      >
        تحميل بيانات الامتحان
      </ColorButton>
    </Box>
  );
}
