import React, { useState, useEffect } from "react";
import { Box, Button, ListItem, Typography } from "@mui/material";
import { useQueryClient } from "@tanstack/react-query";
import CourseExamTimer from "./CourseExamTimer";
import ImageFullSize from "@shared/ImageFullSize/ImageFullSize";
import { useForm } from "react-hook-form";
import { Beforeunload } from "react-beforeunload";
import useCustomPostQuery from "../../../../../hooks/useCustomPostQuery";
import useCustomMutation from "../../../../../hooks/useCustomMutation";
import { HashLoader } from "react-spinners";
import { COLORS } from "@/constants/Colors/colors";
import { api_url } from "@/constants/base_url";
import { useNavigate } from "react-router-dom";
import { useSelector } from 'react-redux';
import { FONTS } from "@/constants/Fonts/fonts";

export function CourseExam({ examId, setStarted, overallExam, courseId }) {
  const { handleSubmit, watch, setValue } = useForm();

  const globalState = useSelector((state) => state?.global);
  const lang = globalState?.lang;
  const isDarkMode = globalState?.isDarkMode;


  function submit(data) {
    const formData = new FormData();
    formData.append("id", examId);
    const anses = data.answers.sort((x, y) => x - y);
    for (let i = 0; i < anses.length; i++) {
      formData.append(`answers[${i}]`, anses[i]);
    }
    mutate(formData);
  }

  const queryClient = useQueryClient();
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [selectedAnswer, setSelectedAnswer] = useState(0);
  const [answers, setAnswers] = useState([]);
  const [selectedImage, setSelectedImage] = useState(null);
  const [displayFullImage, setDisplayFullImage] = useState(false);
  const navigate = useNavigate();
  useEffect(() => {
    if (displayFullImage) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }
  }, [displayFullImage]);
  const {
    data: examQuestions,
    isPending: isExamLoading,
    isError,
    isSuccess,
  } = useCustomPostQuery({
    key: ["exam-questions", examId],
    url: `exam/startExam`,
    data: { id: examId },
    staleTime: 0,
    refetchOnMount: true,
    select: (data) => data,
  });
  useEffect(() => {
    if (isSuccess) {
      setAnswers(
        new Array(examQuestions?.data?.data?.questions?.length).fill(0)
      );
      examQuestions?.data?.data?.draft &&
        setSelectedAnswer(examQuestions?.data?.data?.draft[0]);
    }
  }, [isSuccess]);

  const { mutate, isPending } = useCustomMutation({
    url: `exam/submitExam`,
    onSuccess: () => {
      queryClient.invalidateQueries(["examData", "courseExamsForStudent"]);
      setStarted(false);
      if (overallExam) {
        navigate(`/course/${courseId}`);
      }
    },
    successMsg: lang === 'ar' ? "تم الحفظ بنجاح" : "Answers Saved Successfully",
  });

  if (isExamLoading) {
    return (
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          marginTop: "30px",
          marginBottom: "30px",
        }}
      >
        <HashLoader color={COLORS.PRIMARY.MAIN} />
      </div>
    );
  }


  // ********************************************************


  if (isError) {
    return (
      <Box
        width={"100%"}
        height={60}
        sx={{
          bgcolor: COLORS.PRIMARY.MAIN,
          display: "flex",
          alignItems: "center",
          paddingX: "10px",
        }}
        color={"white"}
      >
        <Typography fontFamily={lang === 'en' ? FONTS.ENGLISH : ""} fontSize={28} color={"white"}>
          {lang === 'ar' ? "لقد بدأ الإمتحان بالفعل" : "Exam is already started"}
        </Typography>
      </Box>
    );
  }

  return (
      <Beforeunload
        onBeforeunload={(event) => {
          event.preventDefault();
          return lang === "ar" ? "هل انت متأكد من انك تريد مغادرة الصفحة؟" : "Are you sure you want to leave this page?";
        }}
      >
        <CourseExamTimer
          remainingTime={examQuestions?.data?.data?.remainingTime}
          setStarted={setStarted}
        />

        <form>
          <Box
            width={"100%"}
            height={60}
            sx={{
              bgcolor: COLORS.PRIMARY.MAIN,
              display: "flex",
              alignItems: "center",
              paddingX: "10px",
            }}
            color={"white"}
          >
            <Typography  fontFamily={lang === 'ar' ? FONTS.BOLD : FONTS.ENGLISH} fontSize={28} color={"white"}>
              {examQuestions?.data?.data?.title}
            </Typography>
          </Box>
          <Box
            style={{ transition: "all 0.5s" }}
            sx={{
              display: "flex",
              gap: "10px",
              flexDirection: "column",
              padding: "30px",
              bgcolor: "grey.300",
              margin: "0 auto",
              textAlign: "center",
            }}
          >
            <Typography
               fontFamily={lang === 'ar' ? FONTS.BOLD : FONTS.ENGLISH}
              fontSize={32}
              color={COLORS.PRIMARY.MAIN}
            >
              {currentQuestion + 1}/{examQuestions?.data?.data?.questions?.length}
            </Typography>
            <Typography fontFamily={lang === 'ar' ? FONTS.BOLD : FONTS.BOLD} fontSize={24} color={"grey.800"}>
              {lang === 'ar' ? "اختر الإجابة الصحيحة" : "Choose the correct answer"}
            </Typography>
            <Typography  fontFamily={lang === 'ar' ? FONTS.BOLD : FONTS.ENGLISH} fontSize={36} color={"grey.800"}>
              {examQuestions?.data?.data?.questions[currentQuestion]?.title}
            </Typography>
            <img
              src={`${api_url.slice(0, -7)}${examQuestions?.data?.data?.questions[currentQuestion]?.image
                }`}
              style={{
                borderRadius: "10px",
                width: "420px",
                cursor: "pointer",
                margin: "0 auto",
              }}
              alt=""
              onClick={() => {
                setDisplayFullImage(true);
                setSelectedImage(
                  ` ${examQuestions?.data?.data?.questions[currentQuestion]?.image}`
                );
              }}
            />
            {displayFullImage && (
              <ImageFullSize
                image={selectedImage}
                setDisplayFullImage={setDisplayFullImage}
              />
            )}
            <ul
              style={{
                listStyle: "none",
                display: "grid",
                gridTemplateColumns: "repeat(2, 1fr)",
                gap: "40px",
              }}
            >
              {examQuestions?.data?.data?.questions[
                currentQuestion
              ]?.answers?.map((item, index) => (
                <ListItem
                  style={{ transition: "all 0.5s" }}
                  onClick={() => {
                    setValue(`answers.${currentQuestion}`, item.id);
                    localStorage.setItem(
                      "formData",
                      JSON.stringify({ ...watch(), id: examId })
                    );
                    setSelectedAnswer(item.id);
                  }}
                  key={index}
                  sx={{
                    bgcolor: `${selectedAnswer === item.id || answers.includes(item.id)
                      ? COLORS.PRIMARY.MAIN
                      : COLORS.WHITE
                      }`,
                    cursor: "pointer",
                    borderRadius: "4px",
                    paddingY: "10px",
                    paddingX: "20px",
                  }}
                >
                  <Typography
                    style={{ transition: "all 0.5s" }}
                     fontFamily={lang === 'ar' ? FONTS.BOLD : FONTS.ENGLISH}
                    fontSize={24}
                    color={`${selectedAnswer === item.id || answers.includes(item.id)
                      ? "white"
                      : "grey.800"
                      }`}
                  >
                    {item.title}
                  </Typography>
                </ListItem>
              ))}
            </ul>
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
              gap: "10px",
              bgcolor: "grey.300",
              paddingX: "30px",
              paddingY: "10px",
            }}
          >
            <Button
              onClick={() => {
                setCurrentQuestion(currentQuestion - 1);
              }}
              disabled={currentQuestion === 0}
              sx={{
                color: COLORS.WHITE,
                bgcolor: COLORS.PRIMARY.MAIN,
                "&:hover": {
                  bgcolor: COLORS.SECONDARY.MAIN,
                },
                "&:disabled": {
                  bgcolor: "grey.400",
                  color: "grey.700",
                },
              }}
            >
              {lang === 'ar' ? "السابق" : "previous"}
            </Button>
            {currentQuestion ===
              examQuestions?.data?.data?.questions?.length - 1 ? (
              <Button
                onClick={handleSubmit(submit)}
                disabled={isPending}
                sx={{
                  color: COLORS.WHITE,
                  bgcolor: COLORS.PRIMARY.MAIN,
                  "&:hover": {
                    bgcolor: COLORS.SECONDARY.MAIN,
                  },
                }}
              >
                {isPending ? (
                  <HashLoader color={COLORS.WHITE} size={20} />
                ) : (
                  lang === 'ar' ? "حفظ" : "Save"
                )}
              </Button>
            ) : (
              <Button
                onClick={() => {
                  setCurrentQuestion(currentQuestion + 1);
                }}
                sx={{
                  color: COLORS.WHITE,
                  bgcolor: COLORS.PRIMARY.MAIN,
                  "&:hover": {
                    bgcolor: COLORS.SECONDARY.MAIN,
                  },
                }}
              >
                {lang === 'ar' ? "التالي" : "Next"}
              </Button>
            )}
          </Box>
        </form>
      </Beforeunload>
  );
}
