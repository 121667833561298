import { COLORS } from "@/constants/Colors/colors";
import { Box, Button, IconButton, Typography } from "@mui/material";
import { useState } from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import useCustomQuery from "@/hooks/useCustomQuery";
import { HashLoader } from "react-spinners";
import { ModuleVideo } from "../ModuleVideo/ModuleVideo";
import { ModuleFile } from "../ModuleFile/ModuleFile";
import { ModuleExam } from "../ModuleExam/ModuleExam";
import { ModuleHomework } from "../ModuleHomework/ModuleHomework";
import { Add } from "../Add/Add";
import DeleteDialog from "../../DeleteDialog";
import EditModule from "../../EditModule";
import { useSelector } from "react-redux";
import { FaEyeSlash } from "react-icons/fa6";
import { FaEye } from "react-icons/fa6";
import { FaTrashAlt } from "react-icons/fa";
import lessonStyle from "./ModuleDetailsCollapse.module.css"

export default function ModuleDetailsCollapse({ module, index, course }) {
  const language = useSelector((state) => state?.global);
  const lang = language?.lang;
  const isDarkMode = language?.isDarkMode;

  const globalState = useSelector((state) => state?.global);
  const [collapse, setCollapse] = useState(true);
  const { data, isPending } = useCustomQuery({
    enabled: !collapse,
    url: `module/adminShow?id=${module?.id}`,
    key: ["module", module, lang],
    retry: false,
    lang,
  });

  const {
    data: files,
    isPending: isFilesPending,
    isError: isFilesError,
  } = useCustomQuery({
    url: `drive?type=module&id=${module?.id}`,
    key: ["module files", module?.id, lang],
    // data: { type: "module", id: module?.id },
    retry: false,
    enabled: !isNaN(+module?.id) && !collapse,
    lang,
  });

  // console.log(`files`, files?.data);

  const {
    data: exams,
    isPending: isExamsPending,
    isError: isExamsError,
  } = useCustomQuery({
    url: `exam/getLessonExamsAdmin?lesson_module_id=${module?.id}`,
    key: ["module exam", module?.id, lang],
    enabled: !collapse,
    retry: false,
    lang,
  });
  const {
    data: homework,
    isPending: isHomeworkPending,
    isError: isHomeworkError,
  } = useCustomQuery({
    url: `homeWork/getHomeWorkAdmin?lesson_module_id=${module?.id}`,
    key: ["module homework", module?.id, lang],
    enabled: !collapse,
    retry: false,
    lang,
  });

  const [isConfirmOpen, setIsConfirmOpen] = useState(false);
  const [deleteData, setDeleteData] = useState();
  const [deleteName, setDeleteName] = useState();
  const [confirmMessage, setConfirmMessage] = useState("");
  const deleteModuleHandler = (module) => {
    setIsConfirmOpen(true);
    setConfirmMessage(
      lang == "ar"
        ? "هل انت متأكد من حذف الحصه"
        : "Are you sure you want to delete the Lesson?"
    );
    setDeleteData(module);

    // setDeleteName(module?.id);
  };

  return (
    <>
      <div className={lessonStyle.lessonContainer}>
        <div className={lessonStyle.lesson}
          style={{ margin: lang === 'ar' ? "0 5px 0 0" : "0 0 0 5px", padding: lang === 'ar' ? "0 10px 0 0" : "0 0 0 10px", borderWidth: lang === 'ar' ? "0 2px 0 0" : "0 0 0 2px" }}
          onClick={() => setCollapse(!collapse)}
        >
          <div className={lessonStyle.details}>
            <p>
              {index < 9 ? "0" + (index + 1) : index + 1}
            </p>

            <div>
              <h3>{module?.name}</h3>
              <p>{module?.overview}</p>
            </div>
          </div>

          <div className={lessonStyle.price}>
            {+module.price > 0
              ? <>{module.price} {lang == "ar" ? "جنيه" : "EGP"}</>

              : lang == "ar" ? "مجاني" : "Free"}
          </div>

          <div className={lessonStyle.buttonsContainer}>
            <button>
              {collapse
                ? <FaEye />

                : <FaEyeSlash />
              }
            </button>

            <EditModule course={course} module={module} />

            <button
              onClick={() => {
                deleteModuleHandler(module);
              }}
            >
              <FaTrashAlt />
            </button>
          </div>
        </div>
        {/* ----------------------------------------------------------------------------------------------------------- */}
        <div
          className={lessonStyle.collapseSection}
          style={{
            height: collapse ? 0 : "auto",
            margin: lang==='ar' ? "0 5px 0 0" : "0 0 0 5px",
            backgroundColor: isDarkMode ? COLORS.DARK_GRAY : COLORS.LIGHT_GRAY,
            borderWidth: lang === 'ar' ? "0 2px 0 0" : "0 0 0 2px"
          }}
        >
          {isPending && isFilesPending && isExamsPending && isHomeworkPending ? (
            <Box
              sx={{
                width: "100%",
                height: 50,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <HashLoader size={20} color={COLORS.PRIMARY.MAIN} />
            </Box>
          ) : (
            <Box
              sx={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                gap: 2,
                paddingY: 2,
              }}
            >
              <Add module={module} homework={homework} exams={exams} />
              {data?.data?.lessons?.map((item, index) => (
                <ModuleVideo video={item} key={index} show={!collapse} />
              ))}
              {!isFilesError &&
                !isFilesPending &&
                files?.data?.map((item, index) => (
                  <ModuleFile id={module?.id} file={item} key={index} />
                ))}
              {!isExamsError && !isExamsPending && (
                <>
                  <ModuleExam exam={exams?.data} module={module} />
                </>
              )}
              {!isHomeworkError && !isHomeworkPending && (
                <ModuleHomework homework={homework?.data} />
              )}
            </Box>
          )}
        </div>
      </div>

      <DeleteDialog
        isConfirmOpen={isConfirmOpen}
        setIsConfirmOpen={setIsConfirmOpen}
        deleteName={deleteName}
        deleteData={deleteData}
        confirmMessage={confirmMessage}
      />
    </>
  );
}
