import { RouterProvider } from "react-router-dom";
import { router } from "./Config/routes";
import { QueryClientProvider } from "@tanstack/react-query";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { RecoilRoot } from "recoil";
import { queryClient } from "./Config/queryClient.config";
import { ThemeProvider, createTheme } from "@mui/material";
import CssBaseline from "@mui/material/CssBaseline";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

function App() {
  // ------------ hooks ------------
  const globalStore = useSelector((state) => state.global);
  const [themeMode, setThemeMode] = useState({
    typography: {
      fontFamily: "Noor-regular",
    },
    palette: {
      mode: "dark",
    },
  });

  const theme = createTheme(themeMode);

  // ----------- side effects -----------

  useEffect(() => {
    setThemeMode({
      typography: {
        fontFamily: "Noor-regular",
      },
      palette: {
        mode: globalStore?.isDarkMode ? "dark" : "light",
      },
    });
  }, [globalStore?.isDarkMode]);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <QueryClientProvider client={queryClient}>
        <RecoilRoot>
          <RouterProvider router={router} />
        </RecoilRoot>
      </QueryClientProvider>
      <ToastContainer
        position="bottom-left"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </ThemeProvider>
  );
}

export default App;
