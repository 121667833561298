import React, { useState } from "react";
import { Close } from "@mui/icons-material";
import {
  Box,
  Button,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import useCustomQuery from "@/hooks/useCustomQuery";
import CustomTableLoading from "@shared/Layout/TableRowLoading/CustomTableLoading";
import DeleteIcon from "@mui/icons-material/Delete";
import { COLORS } from "@/constants/Colors/colors";
import AddDevice from "./AddDevice";
import DeleteAllDevices from "./DeleteAllDevices";
import DeleteDevice from "./DeleteDevice";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";

export default function DevicesBox({ id, closeNote, noteBook }) {
  const lang = useSelector((state) => state?.global?.lang);
  const globalState = useSelector((state) => state?.global);
  const {
    data: tableData,
    error,
    isLoading,
    isError,
    refetch,
  } = useCustomQuery({
    url: `device/getByUser?user_id=${id}`,
    key: ["getByUserDevices", id],
    enabled: !isNaN(+id),
    lang,
  });

  const [addDeviceNote, setAddDeviceNote] = useState(false);
  const [studentId, setStudentId] = useState();
  const [deviceId, setDeviceId] = useState();

  const openAddDevice = (note) => {
    setAddDeviceNote(note);
    console.log(addDeviceNote);
  };

  const closeAddDevice = () => {
    setAddDeviceNote("");
  };
  const getDeviceId = (id) => {
    setDeviceId(id);
  };

  return (
    <>
      <Box
        sx={{
          position: "fixed",
          top: "0",
          left: "0",
          width: "100%",
          height: "100%",
          bgcolor: "#000000ab",
          zIndex: "1501",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          transition: "0.3s",
          opacity: noteBook === "devices" ? "1" : "0",
          visibility: noteBook === "devices" ? "visible" : "hidden",
        }}
      >
        <Box
          mb={2}
          sx={{
            width: "90%",
          }}
        >
          <Button
            onClick={() => {
              openAddDevice("add-device");
            }}
            sx={{
              bgcolor: "green",
              color: "white",
              padding: "7px 20px",
              marginLeft: "10px",
              transition: "0.3s",
              "&:hover": {
                bgcolor: "gray",
              },
            }}
          >
            {lang == "ar" ? "أضف جهاز" : "Add device"}
          </Button>

          {tableData?.data?.length != 0 && (
            <Button
              onClick={() => openAddDevice("del-all-device")}
              sx={{
                bgcolor: "darkred",
                color: "white",
                padding: "7px 20px",
                transition: "0.3s",
                "&:hover": {
                  bgcolor: "gray",
                },
              }}
            >
              {lang == "ar" ? "إعادة ضبط جميع الأجهزة" : "Reset all devices"}
            </Button>
          )}
        </Box>
        <AddDevice
          id={id}
          closeAddDevice={closeAddDevice}
          openAddDevice={addDeviceNote}
          refetch={refetch}
        />
        <DeleteAllDevices
          id={id}
          closeAddDevice={closeAddDevice}
          openAddDevice={addDeviceNote}
          refetch={refetch}
        />

        <DeleteDevice
          id={deviceId}
          closeAddDevice={closeAddDevice}
          openAddDevice={addDeviceNote}
          refetch={refetch}
        />

        <Box
          sx={{
            bgcolor: globalState?.isDarkMode ? COLORS.DARK_GRAY : "white",
            padding: "30px",
            borderRadius: "10px",
            width: "90%",
            height: "auto",
            textAlign: "center",
            position: "relative",
            transition: "0.3s",
            opacity: noteBook === "devices" ? "1" : "0",
            transform: noteBook === "devices" ? "scale(1)" : "scale(0)",
          }}
        >
          <div
            className="close-btn"
            style={{
              position: "absolute",
              top: 10,
              right: 10,
              zIndex: 100,
            }}
          >
            <Close
              onClick={closeNote}
              sx={{
                color: "black",
                width: 30,
                height: 30,
                transition: "0.4s",
                cursor: "pointer",
                "&:hover": {
                  color: "#b0fe6a",
                },
              }}
            />
          </div>

          <TableContainer component={Paper}>
            <Table
              sx={{ direction: "rtl", color: "white" }}
              aria-label="simple table"
            >
              <TableHead sx={{ bgcolor: "#b0fe6a", color: "white" }}>
                <TableRow>
                  <TableCell align="center">
                    <Typography color={COLORS.OFF_WHITE}>
                      {lang == "ar" ? "الرقم" : "Number"}
                    </Typography>
                  </TableCell>

                  <TableCell align="center">
                    <Typography color={COLORS.OFF_WHITE}>
                      {lang == "ar" ? "نوع الجهاز" : "Device type"}
                    </Typography>
                  </TableCell>
                  <TableCell align="center">
                    <Typography color={COLORS.OFF_WHITE}>
                      {lang == "ar" ? "الاعدادت" : "Settings"}
                    </Typography>
                  </TableCell>
                </TableRow>
              </TableHead>
              <Box sx={{ overflowY: "auto", maxHeight: "400px", width: "203%" }}>
                <TableBody>
                  {isLoading ? (
                    <>
                      <CustomTableLoading
                        width={"100%"}
                        height={60}
                        numOfCells={7}
                      />
                    </>
                  ) : (
                    <>
                      {tableData?.data?.length !== 0 ? (
                        <>
                          {tableData?.data?.map((item, i) => (
                            <TableRow
                              key={i}
                              sx={{
                                "&:last-child td, &:last-child th": { border: 0 },
                                cursor: "pointer",
                              }}
                            >
                              <TableCell align="center">{i + 1}</TableCell>
                              {/* <TableCell align="center" component="th" scope="row">
                  {item.deviceId}
                </TableCell> */}
                              <TableCell align="center" sx={{width: "100%"}}>{item?.device}</TableCell>
                              <TableCell align="center">
                                <Box
                                  sx={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                  }}
                                >
                                  <IconButton
                                    onClick={() => {
                                      openAddDevice("del-device"),
                                        getDeviceId(item?.id);
                                    }}
                                    aria-label="delete"
                                    size="small"
                                    sx={{
                                      bgcolor: "#db3a3c",
                                      "&:hover": {
                                        bgcolor: "#db3a3c",
                                      },
                                    }}
                                  >
                                    <DeleteIcon
                                      fontSize="inherit"
                                      sx={{ color: "#fff" }}
                                    />
                                  </IconButton>
                                </Box>
                              </TableCell>
                            </TableRow>
                          ))}
                        </>
                      ) : (
                        <>
                          <TableRow
                            sx={{
                              "&:last-child td, &:last-child th": { border: 0 },
                              cursor: "pointer",
                            }}
                          >
                            <TableCell colSpan={7} align="center">
                              {lang === "ar" ? "لا توجد اجهزة" : "No devices"}
                            </TableCell>
                          </TableRow>
                        </>
                      )}
                    </>
                  )}
                </TableBody>
              </Box>
            </Table>
          </TableContainer>
        </Box>
      </Box>
    </>
  );
}

DevicesBox.propTypes = {
  noteBook: PropTypes.bool,
  closeNote: PropTypes.func,
  id: PropTypes.number,
};
