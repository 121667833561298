import {
  Box,
  Button,
  FormHelperText,
  TextField,
  Typography,
} from "@mui/material";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import { COLORS } from "@/constants/Colors/colors";
import { useState } from "react";
import Modal from "@shared/Modal/Modal";
import { FormProvider, useForm } from "react-hook-form";
import ColorButton from "@shared/Buttons/ColorButton/ColorButton";
import { ClipLoader } from "react-spinners";
import { zodResolver } from "@hookform/resolvers/zod";
import addFileSchema from "../../../schema/addFile.schema";
import useAddCourseFile from "../../hook/useAddFile";
import { useSelector } from 'react-redux';

export const AddFile = ({ module }) => {
  const language = useSelector((state) => state?.global);
  const lang = language?.lang;
  const [open, setOpen] = useState(false);
  const methods = useForm({
    resolver: zodResolver(addFileSchema),
  });
  const {
    register,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm({
    defaultValues: {
      name: "",
      file: "",
      type: "Module",
      id: "",
    },
  });

  const { mutate, isLoading } = useAddCourseFile({
    lang,
    onSettled: close,
  });

  function submit(data) {
    const formData = {
      name: data?.name,
      link: data?.file,
      id: module?.id,
      type: "module",
    };

    mutate(formData);

    setOpen(false);
  }
  return (
    <>
      <Button
        sx={{
          bgcolor: "#248935",
          display: "block",
          color: COLORS.WHITE,
          width: 150,
          height: 150,
          "&:hover": { bgcolor: "#248935", opacity: "0.9" },
        }}
        onClick={() => {
          setOpen(true);
        }}
      >
        <AttachFileIcon fontSize="large" />
        <Typography>{lang == "ar" ? "ملف" : "File"}</Typography>
      </Button>
      <Modal open={open} closeFn={() => setOpen(false)} maxWidth={"sm"}>
        <FormProvider {...methods}>
          <form
            onSubmit={handleSubmit(submit)}
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
              gap: "20px",
              width: "60%",
              margin: "auto",
            }}
          >
            <TextField
              registername={"name"}
              placeholder={lang == "ar" ? "ادخل اسم الملف" : "Enter file name"}
              type="text"
              {...register("name", {
                required:
                  lang == "ar" ? `هذا الحقل مطلوب` : "This field is required",
              })}
              fullWidth
              sx={{
                direction: "rtl",
              }}
            />
            <FormHelperText sx={{ color: COLORS.DANGER }}>
              {errors?.name && errors?.name?.message}
            </FormHelperText>

            <TextField
              registername={"file"}
              placeholder={lang == "ar" ? "الملف" : "file"}
              {...register("file", {
                required: lang == "ar" ? `يجب رفع الملف اولا` : "upload file ",
              })}
              type="text"
              fullWidth
              sx={{
                direction: "rtl",
              }}
            />

            <FormHelperText sx={{ color: COLORS.DANGER }}>
              {errors?.file && errors?.file?.message}
            </FormHelperText>

            <Box
              sx={{
                marginTop: "50px",
                marginBottom: "30px",
                textAlign: "center",
              }}
            >
              <ColorButton
                disabled={isLoading}
                type="submit"
                variant="contained"
                sx={{ bgcolor: "#b0fe6a", marginLeft: "20px" }}
                //   onClick={addRowInSession}
              >
                {isLoading ? (
                  <ClipLoader color="#000" size={20} />
                ) : lang == "ar" ? (
                  "حفظ"
                ) : (
                  "Save"
                )}
              </ColorButton>
            </Box>
          </form>
        </FormProvider>
      </Modal>
    </>
  );
};
