import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  // isDarkMode: localStorage.getItem("isDarkMode") === "false",
  isDarkMode: true,
  lang: localStorage.getItem("selectedLanguage") || "ar",
};

export const globalSlice = createSlice({
  name: "global store",
  initialState,
  reducers: {
    setDarkMode: (state) => {
      const newMode = !state.isDarkMode;
      state.isDarkMode = newMode;
      // localStorage.setItem("isDarkMode", newMode);
      localStorage.setItem("isDarkMode", true);
    },
    setLang: (state, action) => {
      state.lang = action.payload;
      localStorage.setItem("selectedLanguage", action.payload);
    },
  },
});

export const { setDarkMode } = globalSlice.actions;
export const { setLang } = globalSlice.actions;

export default globalSlice.reducer;
